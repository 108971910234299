import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiCreditCard, FiDollarSign } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Link } from 'react-router-dom';
import { Container, FormArea, MessageArea } from './styles';

import Toolbar from '../../components/Toolbar';
import Button from '../../components/Button';
import Select from '../../components/SelectComponent';
import Input from '../../components/Input';
import LoadingAnimation from '../../components/Loading';

import getValidationErrors from '../../utils/getValidationErros';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface SavingsAccountData {
  id: string;
  name: string;
  description: string;
  deleted_at: string;
}

interface SavingsAccountFormData {
  savings_account: SavingsAccountData;
  goal: number;
}

interface SavingsAccountsOptions {
  value: SavingsAccountData;
  label: string;
}

const SavingsAccountGoals: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [savingsAccountsOptions, setSavingsAccountsOptions] = useState<
    SavingsAccountsOptions[]
  >([]);
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SavingsAccountFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          savings_account: Yup.string().required('Escolha uma conta'),
          goal: Yup.number()
            .required('Insira valor numérico para a meta')
            .typeError('Insira valor numérico para a meta')
            .positive('Valor da meta deve ser positivo'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put('savings-account', {
          id: data.savings_account.id,
          name: data.savings_account.name,
          description: data.savings_account.description,
          goal: data.goal * 100,
        });

        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Meta ajustada com sucesso!',
        });

        formRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Houve um erro inesperado, tente novamente mais tarde.',
        });

        formRef.current?.reset();
      }
    },
    [addToast],
  );

  useEffect(() => {
    api.get<SavingsAccountData[]>('savings-account').then((response) => {
      const selectOptions = response.data.map((selectOption) => {
        return { value: selectOption, label: selectOption.name };
      });

      setSavingsAccountsOptions(selectOptions);
      setLoading(false);
    });
  }, []);

  return loading ? (
    <Container>
      <Toolbar />
      <LoadingAnimation />
    </Container>
  ) : (
    <Container>
      <Toolbar />
      {savingsAccountsOptions.length > 0 ? (
        <FormArea>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h2>Registro de Metas</h2>
            <Select
              name="savings_account"
              icon={FiCreditCard}
              placeholder="Poupança"
              selectOptions={savingsAccountsOptions}
            />
            <Input
              name="goal"
              icon={FiDollarSign}
              placeholder="Nova meta"
              hasTip="Insira um valor caso deseje alterar a meta de uma poupança existente"
            />
            <Button type="submit">Registrar Meta</Button>
          </Form>
        </FormArea>
      ) : (
        <MessageArea>
          <div>
            Parece que ainda não há poupanças cadastradas em sua aplicação.
          </div>
          <br />
          <div>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <Link to="savings-account-register">Clique aqui</Link> para fazer
            fazer seu primeiro cadastro agora mesmo!
          </div>
        </MessageArea>
      )}
    </Container>
  );
};

export default SavingsAccountGoals;
