import React, { useRef, useCallback, useState } from 'react';
import { FiList } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Container, FormArea } from './styles';

import Toolbar from '../../components/Toolbar';
import Button from '../../components/Button';
import Input from '../../components/Input';
import SwitchComponent from '../../components/SwitchComponent';

import getValidationErrors from '../../utils/getValidationErros';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface AccountFormData {
  description: string;
  opType: boolean;
}

const AccountRegister: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: AccountFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          description: Yup.string().required('Insira uma descrição'),
        });

        await schema.validate(data, { abortEarly: false });

        const requestData = {
          name: data.description,
          type: data.opType ? 'OUT' : 'IN',
        };

        await api.post('account', requestData);

        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Conta cadastrada com sucesso!',
        });

        formRef.current?.clearField('description');
        formRef.current?.clearField('opType');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const errorMessage = err.response.data.message;

        if (errorMessage === 'Account already registered.') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Conta já registrada',
          });
        }
      }
    },
    [addToast],
  );

  return (
    <Container>
      <Toolbar />
      <FormArea>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Registro de Contas</h2>
          <SwitchComponent
            name="opType"
            options={['Entrada', 'Saída']}
            id="op-type"
          />
          <Input name="description" icon={FiList} placeholder="Nome da conta" />
          <Button type="submit">Registrar Conta</Button>
        </Form>
      </FormArea>
    </Container>
  );
};

export default AccountRegister;
