/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface IOption {
  value: unknown;
  label: string;
}

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  selectOptions: IOption[];
  icon?: React.ComponentType<IconBaseProps>;
}

const Select: React.FC<Props> = ({
  name,
  selectOptions,
  icon: Icon,
  ...rest
}) => {
  const [isFilled, setIsFilled] = useState(false);

  const selectRef = useRef<any>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleSelectBlur = useCallback(() => {
    if (selectRef.current) {
      setIsFilled(!!selectRef?.current?.state.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      clearValue: (ref: any) => {
        ref.select.clearValue();
        setIsFilled(!!selectRef?.current?.state.value);
      },
    });
  }, [fieldName, registerField, rest.isMulti, name]);
  return (
    <Container isErrored={!!error} isFilled={isFilled}>
      {Icon && <Icon size={18} />}
      <ReactSelect
        defaultValue={defaultValue}
        isClearable
        ref={selectRef}
        classNamePrefix="react-select"
        options={selectOptions}
        onBlur={handleSelectBlur}
        {...rest}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c80800" size={18} />
        </Error>
      )}
    </Container>
  );
};
export default Select;
