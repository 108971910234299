import styled from 'styled-components';

import Tooltip from '../Tooltip';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      max-width: 13px;
      height: 13px;
      border-radius: 3px;
      margin-right: 10px;
      -webkit-appearance: none;
      background-color: #a2a2a5;
      position: relative;
    }

    input:active,
    input:checked {
      border: 1px solid #eea302;
      background-color: #eea302;
    }

    input:checked:after {
      content: '\\2714';
      font-size: 14px;
      position: absolute;
      top: -4px;
      left: 0px;
      color: #000;
    }
  }
`;

export const Info = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #eea302;
    width: 500px;
    color: #000;

    &::before {
      border-color: #eea302 transparent;
    }
  }
`;
