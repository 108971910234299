import styled, { keyframes, css } from 'styled-components';

interface FilterBoxProps {
  xPos: number;
  yPos: number;
}

const displayFilterBox = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div<FilterBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 15rem;
  height: 24rem;

  background: #2e2e35;

  position: absolute;

  border-radius: 10px;

  animation: ${displayFilterBox} 0.3s;

  ${(props) =>
    css`
      left: ${props.xPos}%;
      top: ${props.yPos}%;
    `}
`;

export const SelectAllButton = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: baseline;
  align-items: left;

  position: relative;
  left: -2rem;

  button {
    margin-right: 0.5rem;

    width: 100%;
    height: 100%;
    color: #b8b8b8;
    border: none;
    -webkit-appearance: none;
    background-color: #2e2e35;
    position: relative;
    font-size: 16px;
  }
`;

export const SelectAllCheckbox = styled.div`
  padding-top: 0.5rem;
  display: flex;
  justify-content: baseline;

  position: relative;
  left: -1rem;

  label {
    display: flex;
    align-items: baseline;
    font-size: 18px;

    input {
      margin-right: 0.5rem;
      /* padding: 5rem 0; */

      /* margin: 0.5rem 0.5rem 0.7rem 0; */
      width: 13px;
      height: 13px;
      border-radius: 3px;
      -webkit-appearance: none;
      background-color: #a2a2a5;
      position: relative;
    }

    input:active,
    input:checked {
      border: 1px solid #eea302;
      background-color: #eea302;
    }

    input:checked:after {
      content: '\\2714';
      font-size: 14px;
      position: absolute;
      top: -4px;
      left: 0px;
      color: #000;
    }
  }
`;

export const FilterContent = styled.div`
  width: 12rem;
  height: 12rem;
  background: #3e3e44;
  margin-top: 1rem;
  overflow: scroll;
  border-radius: 5px;

  padding: 0.5rem;

  display: flex;
  flex-direction: column;

  label {
    display: flex;
    align-items: baseline;
    font-size: 18px;

    input {
      margin: 0.5rem 0.5rem 0.7rem 0;
      width: 13px;
      height: 13px;
      border-radius: 3px;
      /* margin-right: 10px; */
      -webkit-appearance: none;
      background-color: #a2a2a5;
      position: relative;
    }

    input:active,
    input:checked {
      border: 1px solid #eea302;
      background-color: #eea302;
    }

    input:checked:after {
      content: '\\2714';
      font-size: 14px;
      position: absolute;
      top: -4px;
      left: 0px;
      color: #000;
    }
  }
`;

export const FilterTitle = styled.div`
  padding-top: 1rem;
  color: #7086f5;
`;

export const FilterButton = styled.button`
  background: #eea302;
  color: #000;
  border-radius: 10px;

  width: 12rem;
  height: 3rem;
  margin-top: 1rem;
`;
