import React from 'react';
import { Switch } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import AccountsSummary from '../pages/AccountsSummary';
import TransactionsSummary from '../pages/TransactionsSummary';
import CityRegister from '../pages/CitiesRegister';
import AccountRregister from '../pages/AccountsRegister';
import AccountWeightsRegister from '../pages/AccountsWeightsRegister';
import SavingsAccountRregister from '../pages/SavingsAccountRegister';
import SavingsAccountsGoals from '../pages/SavingsAccountsGoals';
import Transactions from '../pages/Transactions';
import Transfer from '../pages/AccountSavingsTransfer';

import Route from './Route';
import Accounts from '../pages/Accounts';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/forgot" component={ForgotPassword} />
    <Route path="/signup" component={SignUp} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/accounts-summary" component={AccountsSummary} isPrivate />
    <Route
      path="/transactions-summary"
      component={TransactionsSummary}
      isPrivate
    />
    <Route path="/city-register" component={CityRegister} isPrivate />
    <Route path="/account-register" component={AccountRregister} isPrivate />
    <Route
      path="/account-weights-register"
      component={AccountWeightsRegister}
      isPrivate
    />
    <Route
      path="/savings-account-register"
      component={SavingsAccountRregister}
      isPrivate
    />
    <Route
      path="/savings-account-goals"
      component={SavingsAccountsGoals}
      isPrivate
    />
    <Route path="/transactions" component={Transactions} isPrivate />
    <Route path="/transfers" component={Transfer} isPrivate />
    <Route path="/accounts" component={Accounts} isPrivate />
  </Switch>
);

export default Routes;
