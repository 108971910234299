/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useCallback, useState, useRef, useEffect } from 'react';

import { FormHandles } from '@unform/core';
import { Link } from 'react-router-dom';
import { isSameMonth } from 'date-fns';

import { FiAlertCircle } from 'react-icons/fi';
import {
  Container,
  Form,
  SummaryCard,
  TotalSummary,
  AccountsSummaryArea,
  Subtitle,
  SubtitleText,
  TextContainer,
  AccountDataContainer,
  AccountText,
  ValuesContainer,
  AccountData,
  AccountContainer,
} from './styles';

import Toolbar from '../../components/Toolbar';
import MonthPicker from '../../components/MonthPicker';
import LoadingAnimation from '../../components/Loading';

import api from '../../services/api';
import formatValue from '../../utils/formatValue';
import Tooltip from '../../components/Tooltip';

interface AccountData {
  id: string;
  name: string;
  type: string;
  active: boolean;
  weight?: number;
}

interface SummaryData {
  income: string;
  outcome: string;
  balance: string;
}

interface AccountState {
  account_id: string;
  name: string;
  initial_balance: number;
  amount_spent: number;
  final_balance: number;
  type: string;
}

interface SavingsAccountState {
  account_id: string;
  name: string;
  description: string;
  amount_saved: number;
  goal: number;
  deleted_at?: string;
}

interface States {
  accounts: AccountState[];
  savings_accounts: SavingsAccountState[];
}

interface FormattedAccountState {
  account_id: string;
  name: string;
  initial_balance: number;
  amount_spent: number;
  final_balance: number;
  formatted_initial_balance: string;
  formatted_amount_spent: string;
  formatted_final_balance: string;
  hasWeight?: boolean;
  type: string;
}

interface FormattedSavingsAccountState {
  account_id: string;
  name: string;
  description: string;
  amount_saved: number;
  goal: number;
  formatted_amount_saved: string;
  formatted_goal: string;
}

const AccountsSummary: React.FC = () => {
  const [accountState, setAccountState] = useState<FormattedAccountState[]>([]);
  const [savingsAccountState, setSavingsAccountState] = useState<
    FormattedSavingsAccountState[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(new Date());
  const [accounts, setAccounts] = useState<AccountData[]>([]);
  const [hasOutcomeAccounts, setHasOutcomeAccounts] = useState(false);
  const [summary, setSummary] = useState<SummaryData>({} as SummaryData);

  const dateFormRef = useRef<FormHandles>(null);

  const handleDateChange = useCallback((compDate) => {
    isSameMonth(new Date(), compDate) ? setDate(new Date()) : setDate(compDate);
  }, []);

  // Fetch accounts data ------------------------
  useEffect(() => {
    api.get<AccountData[]>('account').then((response) => {
      setAccounts(response.data);

      const outcomeAccounts = response.data.filter((account) => {
        return account.type === 'OUT';
      });

      if (outcomeAccounts.length > 0) setHasOutcomeAccounts(true);
    });
  }, [date]);

  // Fetch states --------------
  useEffect(() => {
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      year: 'numeric',
    })
      .format(date)
      .replace('/', '-');

    api
      .get<States>('states', {
        params: {
          date: formattedDate,
        },
      })
      .then((response) => {
        const outcomeAccs = accounts.filter((acc) => acc.type === 'OUT');
        const accStates = response.data.accounts.map((state) => {
          const account = outcomeAccs.find(
            (acc: AccountData) => state.account_id === acc.id,
          );

          if (account) {
            return {
              account_id: state.account_id,
              name: state.name,
              initial_balance: state.initial_balance,
              amount_spent: state.amount_spent,
              final_balance: state.final_balance,
              formatted_initial_balance: formatValue(
                state.initial_balance / 100,
              ),
              formatted_amount_spent: formatValue(state.amount_spent / 100),
              formatted_final_balance: formatValue(state.final_balance / 100),
              hasWeight: !!(account.weight && account.weight > 0),
              type: account.type,
            };
          }

          return {
            account_id: state.account_id,
            name: state.name,
            initial_balance: 0,
            amount_spent: 0,
            final_balance: 0,
            formatted_initial_balance: 'R$ --',
            formatted_amount_spent: 'R$ --',
            formatted_final_balance: 'R$ --',
            hasWeight: true,
            type: 'OUT',
          } as FormattedAccountState;
        });
        setAccountState(accStates);

        const formattedSavingsStates = response.data.savings_accounts
          .filter((acc) => {
            if (!acc.deleted_at) {
              return true;
            }

            const [month, year] = acc.deleted_at?.split('-');
            const [currMonth, currYear] = formattedDate.split('-');

            return month >= currMonth && year >= currYear;
          })
          .map((acc) => {
            return {
              account_id: acc.account_id,
              name: acc.name,
              description: acc.description,
              amount_saved: acc.amount_saved,
              goal: acc.goal,
              formatted_amount_saved: formatValue(acc.amount_saved / 100),
              formatted_goal: formatValue(acc.goal / 100),
            };
          });
        setSavingsAccountState(formattedSavingsStates);
      });
  }, [date, accounts]);

  // Summary cards data
  useEffect(() => {
    const totalIncome = accountState.reduce((total, state) => {
      return total + Number(state.initial_balance);
    }, 0);

    const totalOutcome = accountState.reduce((total, state) => {
      return total + Number(state.amount_spent);
    }, 0);

    let currentBalance = +(totalIncome - totalOutcome).toFixed(2);
    if (currentBalance > -0.01 && currentBalance < 0.01) {
      currentBalance = 0;
    }

    const formattedIncome = formatValue(totalIncome / 100);
    const formattedBalance = formatValue(currentBalance / 100);
    const formattedOutcome = formatValue(totalOutcome / 100);

    const updatedSummary = {
      income: formattedIncome,
      outcome: formattedOutcome,
      balance: formattedBalance,
    };

    setSummary(updatedSummary);
    setLoading(false);
  }, [accountState]);

  return loading ? (
    <Container>
      <Toolbar />
      <LoadingAnimation />
    </Container>
  ) : (
    <Container>
      <Toolbar />
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <Form ref={dateFormRef} onSubmit={() => {}}>
        <MonthPicker name="month" clickEvent={handleDateChange} />
      </Form>
      <TotalSummary>
        <SummaryCard>
          <div>Total Entradas</div>
          <div>{summary.income}</div>
        </SummaryCard>
        <SummaryCard>
          <div>Total de Saídas</div>
          <div>{summary.outcome}</div>
        </SummaryCard>
        <SummaryCard>
          <div>Saldo Atual</div>
          <div>{summary.balance}</div>
        </SummaryCard>
      </TotalSummary>
      <AccountsSummaryArea>
        {hasOutcomeAccounts || accountState.length > 0 ? (
          <>
            {accountState.length > 0 && (
              <AccountContainer>
                <Subtitle>Contas</Subtitle>
                <div className="accCard">
                  {accountState.map((state) => {
                    return (
                      <AccountData>
                        <SubtitleText>{state.name}</SubtitleText>
                        <AccountDataContainer>
                          <TextContainer>
                            <AccountText>Saldo Inicial: </AccountText>
                            <AccountText>Saídas: </AccountText>
                            <AccountText>Saldo Final: </AccountText>
                          </TextContainer>
                          <ValuesContainer>
                            <AccountText>
                              {state.formatted_initial_balance}
                            </AccountText>
                            <AccountText>
                              {state.formatted_amount_spent}
                            </AccountText>
                            <AccountText
                              className={
                                state.formatted_final_balance?.includes('-')
                                  ? 'negativo'
                                  : 'positivo'
                              }
                            >
                              {state.formatted_final_balance}
                            </AccountText>
                          </ValuesContainer>
                        </AccountDataContainer>
                        {!state.hasWeight && (
                          <div className="tooltip">
                            <Tooltip title="Ainda não há pesos cadastrados para essa conta">
                              <FiAlertCircle color="#c80800" size={18} />
                            </Tooltip>
                          </div>
                        )}
                      </AccountData>
                    );
                  })}
                </div>
              </AccountContainer>
            )}
            {/* savings accounts table */}
            {savingsAccountState.length > 0 ? (
              <AccountContainer>
                <Subtitle>Poupanças</Subtitle>
                <div className="accCard">
                  {savingsAccountState.map((savingsAccount) => {
                    return (
                      <AccountData>
                        <SubtitleText>{savingsAccount.name}</SubtitleText>
                        <AccountDataContainer>
                          <TextContainer>
                            <AccountText>Valor poupado: </AccountText>
                            <AccountText>Meta: </AccountText>
                          </TextContainer>
                          <ValuesContainer>
                            <AccountText
                              className={
                                Number(savingsAccount.amount_saved) <
                                Number(savingsAccount.goal)
                                  ? 'none'
                                  : 'positivo'
                              }
                            >
                              {savingsAccount.formatted_amount_saved}
                            </AccountText>
                            <AccountText>
                              {savingsAccount.formatted_goal}
                            </AccountText>
                          </ValuesContainer>
                        </AccountDataContainer>
                      </AccountData>
                    );
                  })}
                </div>
              </AccountContainer>
            ) : (
              <>
                <Subtitle>Poupanças</Subtitle>
                <div>Você ainda não tem poupanças cadastradas.</div>
                <br />
                <div>
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <Link to="savings-account-register">Clique aqui</Link> para
                  criar e comece a poupar agora mesmo!
                </div>
                <br />
              </>
            )}
          </>
        ) : (
          <>
            <div>
              Parece que ainda não há contas de saída ou poupanças cadastradas
              em sua aplicação.
            </div>
            <br />
            <div>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <Link to="account-register">Clique aqui</Link> para fazer fazer
              seu primeiro cadastro agora mesmo!
            </div>
          </>
        )}
      </AccountsSummaryArea>
    </Container>
  );
};

export default AccountsSummary;
