const formatDate = (date: Date): string => {
  const newDate = new Date(date);

  return Intl.DateTimeFormat('pt-BR', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(newDate);
};

const parseDateStr = (sep: string, dateStr?: string): Date => {
  if (!dateStr) {
    return new Date();
  }
  const [transactionDay, transactionMonth, transactionYear] = dateStr.split(
    sep,
  );

  return new Date(
    Number(transactionYear),
    Number(transactionMonth) - 1,
    Number(transactionDay),
  );
};

export { formatDate, parseDateStr };
