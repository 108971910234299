import React, { useRef, useCallback } from 'react';
import { FiList } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Container, FormArea } from './styles';

import Toolbar from '../../components/Toolbar';
import Button from '../../components/Button';
import Input from '../../components/Input';

import getValidationErrors from '../../utils/getValidationErros';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface SavingsAccountFormData {
  name: string;
  description: string;
  initialBalance?: number | string;
  goal: number;
}

const SavingsAccountRregister: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SavingsAccountFormData) => {
      try {
        formRef.current?.setErrors({});
        let schema;

        if (data.initialBalance) {
          schema = Yup.object().shape({
            name: Yup.string().required('Insira um nome'),
            description: Yup.string().required('Insira uma descrição'),
            initialBalance: Yup.number()
              .typeError('Insira valor numérico para o saldo inicial')
              .min(0, 'Valor da meta deve ser positivo'),
            goal: Yup.number()
              .required('Insira valor numérico para a meta')
              .typeError('Insira valor numérico para a meta')
              .positive('Valor da meta deve ser positivo'),
          });
        } else {
          schema = Yup.object().shape({
            name: Yup.string().required('Insira um nome'),
            description: Yup.string().required('Insira uma descrição'),
            goal: Yup.number()
              .required('Insira valor numérico para a meta')
              .typeError('Insira valor numérico para a meta')
              .positive('Valor da meta deve ser positivo'),
          });
        }

        await schema.validate(data, { abortEarly: false });

        await api.post('savings-account', {
          name: data.name,
          description: data.description,
          initial_balance:
            data.initialBalance === '' ? 0 : Number(data.initialBalance) * 100,
          goal: Number(data.goal) * 100,
          type: 'SAVINGS',
        });

        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Conta cadastrada com sucesso!',
        });

        formRef.current?.reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const errorMessage = err.response.data.message;

        if (errorMessage === 'Savings account already registered.') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Poupança já registrada',
          });
        }
      }
    },
    [addToast],
  );
  return (
    <Container>
      <Toolbar />
      <FormArea>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Registro de Poupanças</h2>
          <Input name="name" icon={FiList} placeholder="Nome" />
          <Input name="description" icon={FiList} placeholder="Descrição" />
          <Input
            name="initialBalance"
            icon={FiList}
            placeholder="Valor Inicial (opcional)"
          />
          <Input name="goal" icon={FiList} placeholder="Meta" />
          <Button type="submit">Registrar Poupança</Button>
        </Form>
      </FormArea>
    </Container>
  );
};

export default SavingsAccountRregister;
