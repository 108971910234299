import styled, { keyframes } from 'styled-components';
import { Form as unformForm } from '@unform/web';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
`;

export const Form = styled(unformForm)`
  width: 90vw;
  margin: 140px 5% 0 5%;
  display: flex;
  /* align-items: baseline; */
  position: relative;

  div {
    input {
      background: #000008;
    }
  }
`;

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const TotalSummary = styled.div`
  @media only screen and (min-width: 601px) {
    width: 90vw;

    animation: ${appearFromBottom} 1s;

    display: flex;
    justify-content: space-between;

    margin: 32px 0;
  }

  @media only screen and (max-width: 601px) {
    width: 90vw;

    animation: ${appearFromBottom} 1s;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 32px 0;
  }
`;

export const SummaryCard = styled.div`
  @media only screen and (min-width: 601px) {
    width: 300px;
    height: 120px;

    background-color: #eea302;
    color: #000000;

    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    font-weight: 500;
    font-size: 24px;
  }

  @media only screen and (max-width: 601px) {
    width: 100%;
    height: 100px;

    background-color: #eea302;
    color: #000000;

    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    font-weight: 500;
    font-size: 24px;

    margin: 12px 0;
  }
`;

export const AccountsSummaryArea = styled.div`
  @media only screen and (min-width: 601px) {
    display: flex;
    flex-direction: column;
    /* align-items: stretch; */
    /* justify-content: baseline; */
    text-align: center;

    animation: ${appearFromBottom} 1s;

    width: 90%;
    /* height: 700px; */
    /* margin-top: 24px; */
    margin: 8px 5%;
    padding: 56px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    background: #17171e;
    border-radius: 10px;

    form {
      width: 370px;

      h2 {
        font-size: 32px;
        color: #7086f5;
        padding-bottom: 48px;
      }

      input {
        width: 280px;
      }
    }

    a {
      color: #eea302;
    }
  }

  @media only screen and (max-width: 601px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    animation: ${appearFromBottom} 1s;

    width: 100%;
    /* margin: 8px 5%; */
    /* padding: 56px; */
    padding-bottom: 56px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    background: #17171e;
    /* border-radius: 10px; */

    form {
      width: 370px;

      h2 {
        font-size: 32px;
        color: #7086f5;
        padding-bottom: 48px;
      }

      input {
        width: 280px;
      }
    }

    a {
      color: #eea302;
    }
  }
`;

export const Subtitle = styled.div`
  display: flex;

  font-size: 26px;
  font-weight: 500;
  color: #7086f5;

  margin-top: 24px;
`;

export const TransactionsTable = styled.table`
  border-spacing: 0 16px;
  margin-bottom: 56px;

  tr {
    background: #a2a2a5;
    color: #000000;
    text-align: justify;

    th {
      &:first-child {
        max-width: 50px;
      }

      background: #17171e;
      color: #b8b8b8;
      font-size: 22px;
      font-weight: 500;
      text-align: center;

      margin-left: 32px;

      border: 0;
    }

    td {
      &:first-child {
        border-radius: 5px 0 0 5px;
        width: 18%;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }

      padding: 8px 0;
      text-align: center;
      width: 25%;
      font-size: 18px;
    }
  }

  .positivo {
    color: #006000;
  }

  .negativo {
    color: #c80800;
  }
`;

export const AccountData = styled.div`
  @media only screen and (min-width: 601px) {
    display: flex;
    flex-direction: column;
    width: 30%;
    border-radius: 10px;

    margin-top: 16px;
    margin-bottom: 32px;
    background-color: #17171e;
    /* overflow-x: ; */

    [class*='tooltip'] {
      width: 100%;
      background-color: #b8b8b8;
      border-radius: 10px;
      padding-top: 8px;
    }
  }

  @media only screen and (max-width: 601px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    /* background-color: #b8b8b8; */
    align-items: center;

    justify-content: space-evenly;
    background-color: #17171e;
  }
`;

export const AccountDataContainer = styled.div`
  @media only screen and (min-width: 601px) {
    display: flex;
    /* width: 33%; */
    border-radius: 10px;
    background-color: #b8b8b8;

    justify-content: space-evenly;
  }

  @media only screen and (max-width: 601px) {
    display: flex;
    width: 95%;
    border-radius: 10px;
    background-color: #b8b8b8;

    justify-content: space-evenly;
  }
`;

export const TextContainer = styled.div`
  text-align: left;
  width: 45%;
`;

export const ValuesContainer = styled.div`
  text-align: right;
  width: 45%;

  [class*='positivo'] {
    color: #006000;
  }

  [class*='negativo'] {
    color: #c80800;
  }
`;

export const AccountText = styled.div`
  color: #000000;
  font-size: 24px;
  margin: 16px 0px;
`;

export const SubtitleText = styled.div`
  @media only screen and (min-width: 601px) {
    font-size: 22px;
    font-weight: 500;
    color: #7086f5;
    background-color: #17171e;

    margin: 32px 0 16px 0;
  }

  @media only screen and (max-width: 601px) {
    font-size: 22px;
    font-weight: 500;
    color: #7086f5;
    background-color: #17171e;

    padding: 32px 0 16px 0;
  }
`;

export const AccountContainer = styled.div`
  @media only screen and (min-width: 601px) {
    display: flex;
    flex-direction: column;

    .accCard {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  @media only screen and (max-width: 601px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .accCard {
      width: 100%;
      align-items: center;
    }
  }
`;
