import React, { useCallback, useState } from 'react';

import {
  Container,
  FilterTitle,
  FilterContent,
  FilterButton,
  SelectAllCheckbox,
  SelectAllButton,
} from './styles';

interface FilterOptions {
  value: string;
  checked: boolean;
}

interface FilterBoxProps {
  title: string;
  xPos?: number;
  yPos?: number;
  filterOptions: FilterOptions[];
  filterType: string;
  getFilters: (filters: FilterOptions[], type: string) => void;
}

const TableFilter: React.FC<FilterBoxProps> = ({
  xPos = 0,
  yPos = 100,
  title,
  filterOptions,
  filterType,
  getFilters,
}) => {
  const [checkboxContents, setCheckboxContents] = useState(filterOptions);

  const inputRef = filterOptions.map(() => React.createRef<HTMLInputElement>());

  const handleCheck = useCallback(
    (option) => {
      const currentStates = checkboxContents;

      const updatedStates = currentStates.map((state) => {
        if (state.value === option.value) {
          return {
            value: option.value,
            checked: !state.checked,
          };
        }
        return state;
      });

      setCheckboxContents(updatedStates);
    },
    [checkboxContents],
  );

  const handleSetFiter = useCallback(() => {
    getFilters(checkboxContents, filterType);
  }, [checkboxContents, getFilters, filterType]);

  const handleCheckAll = useCallback(() => {
    const currentStates = checkboxContents;

    const updatedStates = currentStates.map((state) => {
      return {
        value: state.value,
        checked: !state.checked,
      };
    });

    inputRef.map(
      (ref) => ref.current && (ref.current.checked = !ref.current.checked),
    );

    setCheckboxContents(updatedStates);
  }, [checkboxContents, inputRef]);

  return (
    <Container xPos={xPos} yPos={yPos}>
      <FilterTitle>{title}</FilterTitle>
      <SelectAllButton>
        <button id="select_all" type="button" onClick={handleCheckAll}>
          Selecionar tudo
        </button>
        {/* <label htmlFor="select_all">
          <input
            id="select_all"
            type="checkbox"
            onClick={handleCheckAll}
            checked
          />
          Selecionar tudo
        </label> */}
      </SelectAllButton>
      <FilterContent>
        {filterOptions.map((option, index) => (
          <label htmlFor={option.value}>
            <input
              ref={inputRef[index]}
              id={option.value}
              type="checkbox"
              value={option.value}
              onClick={() => handleCheck(option)}
              defaultChecked={option.checked}
            />
            {option.value}
          </label>
        ))}
      </FilterContent>
      <FilterButton onClick={() => handleSetFiter()}>Filtar</FilterButton>
    </Container>
  );
};

export default TableFilter;
