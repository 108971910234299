import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding: 16px 0 16px 16px;

  width: 100%;
  height: 56px;
  margin-bottom: 16px;
  border: 2px solid #17171e;

  background: #2e2e35;
  border-radius: 10px;

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c80800;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #eea302;
    `}

  div:first-of-type {
    flex: 1;
  }

  .react-select {
    &__menu {
      /* display: flex; */

      background: #2e2e35;
      border: 0;

      color: #b8b8b8;
      border-radius: 5px;
    }

    &__control--is-focused {
      border: 0;
      box-shadow: none;
    }

    &__option {
      display: flex;
    }

    &__option--is-focused {
      background: #a2a2a5;
      border: 0;

      color: #000008;
    }

    &__option--is-selected {
      background: #a2a2a5;
      border: 0;

      color: #000008;
    }

    &__control {
      background: #2e2e35;
      border: 0;
      color: #b8b8b8;
    }

    &__single-value {
      color: #b8b8b8;
    }
  }

  svg {
    margin-right: 8px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  /* margin-left: 16px; */

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
