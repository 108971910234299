import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiPower } from 'react-icons/fi';

import { Container, Links, MobileMenu, MobileMenuIcon } from './styles';

import MenuButton from './MenuButton';
import DropdownContent from './DropdownContent';

import logoImg from '../../assets/logo.svg';
import logoMobileImg from '../../assets/logo-mobile.svg';

import { useAuth } from '../../hooks/auth';

const Toolbar: React.FC = () => {
  const { signOut } = useAuth();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <Container>
      <img src={logoImg} alt="MyFinances" className="desktop" />
      <img src={logoMobileImg} alt="MyFinances" className="mobile" />

      <Links>
        <MenuButton title="Resumo">
          <DropdownContent>
            <Link to="accounts-summary">Contas</Link>
            <Link to="transactions-summary">Movimentações</Link>
          </DropdownContent>
        </MenuButton>

        <MenuButton title="Transações">
          <DropdownContent>
            <Link to="transactions">Registrar transação</Link>
            <Link to="transfers">Transferências para poupança</Link>
          </DropdownContent>
        </MenuButton>

        <MenuButton title="Cadastros">
          <DropdownContent>
            <Link to="account-register">Conta</Link>
            <Link to="account-weights-register">Pesos</Link>
            <Link to="savings-account-register">Poupanças</Link>
            <Link to="savings-account-goals">Metas</Link>
          </DropdownContent>
        </MenuButton>

        <Link to="/accounts" id="accounts">
          Contas
        </Link>

        <Link to="/" id="dashboard">
          Dashboard
        </Link>

        <button
          id="signout"
          type="button"
          onClick={signOut}
          className="desktop"
        >
          <FiPower size={24} />
        </button>

        {/* Hamburger menu icon for mobile */}
        <MobileMenuIcon onClick={toggleMobileMenu}>&#9776;</MobileMenuIcon>

        {/* Mobile menu content */}
        {mobileMenuOpen && (
          <MobileMenu>
            <Link to="accounts-summary">Contas</Link>
            <Link to="transactions-summary">Movimentações</Link>
            <Link to="transactions">Registrar transação</Link>
            <Link to="transfers">Transferências para poupança</Link>
            <Link to="account-register">Conta</Link>
            <Link to="account-weights-register">Pesos</Link>
            <Link to="savings-account-register">Poupanças</Link>
            <Link to="savings-account-goals">Metas</Link>
            <Link to="/accounts">Contas</Link>
            <Link to="/">Dashboard</Link>
            <button type="button" onClick={signOut}>
              <FiPower size={24} />
            </button>
          </MobileMenu>
        )}
      </Links>
    </Container>
  );
};

export default Toolbar;
