import React, { useState, useCallback, useRef, useEffect } from 'react';
import Switch from 'react-switch';

import { useField } from '@unform/core';

import { Container } from './styles';

interface SwitchProps {
  name: string;
  options: string[];
  id: string;
  isChecked?: boolean;
  clickEvent?: () => void;
  isDisabled?: boolean;
  reset?: boolean;
}

const SwitchComponent: React.FC<SwitchProps> = ({
  name,
  isChecked = false,
  id,
  options,
  clickEvent,
  isDisabled = false,
  reset = false,
  ...rest
}) => {
  const switchRef = useRef<Switch>(null);
  const [checked, setChecked] = useState(isChecked);
  const { fieldName, registerField } = useField(name);

  const handleCheck = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  useEffect(() => {
    if (reset) {
      setChecked(false);
    }
  }, [reset]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: switchRef.current,
      path: 'props.checked',
      clearValue: () => setChecked(false),
    });
  }, [fieldName, registerField]);

  return (
    <Container htmlFor={id} onClick={clickEvent}>
      <Switch
        onChange={handleCheck}
        checked={checked}
        handleDiameter={10}
        height={18}
        width={38}
        uncheckedIcon={false}
        checkedIcon={false}
        offColor="#eea302"
        onColor="#eea302"
        offHandleColor="#000008"
        onHandleColor="#000008"
        ref={switchRef}
        id={id}
        disabled={isDisabled}
        {...rest}
      />
      {checked ? <span>{options[1]}</span> : <span>{options[0]}</span>}
    </Container>
  );
};

export default SwitchComponent;
