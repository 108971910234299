import React, { useRef, useCallback, useEffect, useState } from 'react';
import { FiCreditCard, FiDollarSign } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format } from 'date-fns';

import { Container, FormArea } from './styles';

import Toolbar from '../../components/Toolbar';
import Button from '../../components/Button';
import Select from '../../components/SelectComponent';
import Input from '../../components/Input';
import DayPicker from '../../components/DayPicker';

import getValidationErrors from '../../utils/getValidationErros';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface AccountData {
  id: string;
  name: string;
  type: string;
  active: boolean;
  weight?: number;
  deleted_at?: string;
}

interface SavingsAccountData {
  id: string;
  name: string;
  deleted_at: string;
}

interface TransactionsFormData {
  date: Date;
  amount: number;
  account: AccountData;
  savingsAccount: SavingsAccountData;
}

interface SelectAccountsOptions {
  value: AccountData;
  label: string;
}

interface SelectSavingsAccountsOptions {
  value: SavingsAccountData;
  label: string;
}

const Transfer: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [accountOptions, setAccountOptions] = useState<SelectAccountsOptions[]>(
    [],
  );
  const [savingsAccountOptions, setSavingsAccountOptions] = useState<
    SelectSavingsAccountsOptions[]
  >([]);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: TransactionsFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          account: Yup.string().required('Escolha uma conta'),
          savingsAccount: Yup.string().required('Escolha uma Poupança'),
          amount: Yup.number()
            .typeError('Valor deve ser um número')
            .required('Insira um valor numérico para a transação'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('transfer', {
          date: format(data.date, 'dd/MM/yyyy'),
          amount: Math.round(Number(data.amount) * 100),
          origin_id: data.account.id,
          destination_id: data.savingsAccount.id,
        });

        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Transação cadastrada com sucesso!',
        });

        formRef.current?.clearField('amount');
        formRef.current?.clearField('account');
        formRef.current?.clearField('savingsAccount');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const errorMessage = err.response.data.message;

        if (errorMessage === 'Account not found.') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Conta não encontrada, tente novamente.',
          });

          return;
        }

        if (errorMessage === 'Savings account not found.') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Poupança não encontrada, tente novamente',
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Houve um erro inesperado, tente novamente mais tarde.',
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    api.get<AccountData[]>('account').then((response) => {
      const selectOptions = response.data
        .filter((account) => {
          return account.type === 'OUT' && !account.deleted_at;
        })
        .map((selectOption) => {
          return { value: selectOption, label: selectOption.name };
        });

      setAccountOptions(selectOptions);
    });
  }, []);

  useEffect(() => {
    api.get<SavingsAccountData[]>('savings-account').then((response) => {
      const selectOptions = response.data
        .filter((account) => {
          return !account.deleted_at;
        })
        .map((selectOption) => {
          return { value: selectOption, label: selectOption.name };
        });

      setSavingsAccountOptions(selectOptions);
    });
  }, []);

  return (
    <Container>
      <Toolbar />
      <FormArea>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Transferências para poupança</h2>
          <DayPicker name="date" />
          <Select
            name="account"
            icon={FiCreditCard}
            placeholder="Conta"
            selectOptions={accountOptions}
          />
          <Select
            name="savingsAccount"
            icon={FiCreditCard}
            placeholder="Poupança"
            selectOptions={savingsAccountOptions}
          />
          <Input name="amount" icon={FiDollarSign} placeholder="Valor" />
          <Button type="submit">Realizar Transferência</Button>
        </Form>
      </FormArea>
    </Container>
  );
};

export default Transfer;
