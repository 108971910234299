import React, { useRef, useCallback, useEffect, useState } from 'react';
import { FiMapPin } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Container, FormArea } from './styles';

import Toolbar from '../../components/Toolbar';
import Button from '../../components/Button';
import Select from '../../components/SelectComponent';
import Input from '../../components/Input';

import getValidationErrors from '../../utils/getValidationErros';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface StateData {
  id: string;
  name: string;
}

interface CityFormData {
  state: StateData;
  city: string;
}

interface SelectData {
  value: StateData;
  label: string;
}

const CityRegister: React.FC = () => {
  const [selectStates, setSelectStates] = useState<SelectData[]>([]);

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: CityFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          state: Yup.string().required('Escolha um estado'),
          city: Yup.string().required('Digite o nome da cidade'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('cities', {
          city_name: data.city,
          state_id: data.state.id,
        });

        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Cidade cadastrada com sucesso!',
        });

        formRef.current?.clearField('city');
        formRef.current?.clearField('state');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const errorMessage = err.response.data.message;

        if (errorMessage === 'City already registered.') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Cidade já registrada',
          });
        }
      }
    },
    [addToast],
  );

  useEffect(() => {
    api.get<StateData[]>('states').then((response) => {
      const selectOptions = response.data.map((selectOption) => {
        return { value: selectOption, label: selectOption.name };
      });

      setSelectStates(selectOptions);
    });
  }, []);

  return (
    <Container>
      <Toolbar />
      <FormArea>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Registro de Cidades</h2>
          <Select
            name="state"
            icon={FiMapPin}
            placeholder="Estado"
            selectOptions={selectStates}
          />
          <Input name="city" icon={FiMapPin} placeholder="Cidade" />
          <Button type="submit">Registrar Cidade</Button>
        </Form>
      </FormArea>
    </Container>
  );
};

export default CityRegister;
