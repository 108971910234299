/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { FiDollarSign } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Link } from 'react-router-dom';
import { Container, FormArea, WeightContainer, MessageArea } from './styles';

import Toolbar from '../../components/Toolbar';
import Button from '../../components/Button';
import Input from '../../components/Input';
import formatValue from '../../utils/formatValue';
import LoadingAnimation from '../../components/Loading';

import getValidationErrors from '../../utils/getValidationErros';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface AccountData {
  id: string;
  name: string;
  type: string;
  active: boolean;
  weight?: number;
}

interface AccountValues {
  [key: string]: number;
}

const AccountWeightsRegister: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState<AccountData[]>([]);
  const [weights, setWeights] = useState<AccountValues>({});
  const [results, setResults] = useState<AccountValues>({});
  const [baseValue, setBaseValue] = useState(0);
  const [amountRemaning, setAmountRemaining] = useState('0,00');

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(async () => {
    try {
      // Valida soma dos pesos
      const weightSum = Object.values(weights)
        .reduce((acc, curr) => acc + curr, 0)
        .toFixed(2);

      if (weightSum !== baseValue.toFixed(2)) {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Soma dos pesos deve ser igual ao valor de referência',
        });

        return;
      }

      // Valida se há valor inicial definido
      if (baseValue === 0) {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Valor base deve ser maior que 0',
        });

        return;
      }

      const payload = Object.entries(results).map(([key, value]) => {
        const val = Number(value).toFixed(2);
        const weightVal = Number(val) * 100;
        return {
          account_id: key,
          weight: Math.trunc(weightVal),
        };
      });

      let hasError = false;

      try {
        await api.post('account-weight', payload);
      } catch (err) {
        hasError = true;
      }

      if (!hasError) {
        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Pesos cadastrados com sucesso!',
        });

        formRef.current?.clearField('refValue');

        const newAccountValues = {};
        Object.keys(weights).forEach((key) => {
          formRef.current?.clearField(key);
          Object.assign(newAccountValues, { [key]: 0 });
        });

        setResults(newAccountValues);
        setWeights(newAccountValues);
        setAmountRemaining('R$ 0,00');
        setBaseValue(0);
      } else {
        addToast({
          type: 'error',
          title: 'Erro de registro',
          description: 'Houve um erro na criação de um ou mais registros',
        });

        hasError = false;
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: 'Houve um erro no cadastro dos pesos.',
      });
    }
  }, [addToast, weights, baseValue, results]);

  const handleBaseValueChange = useCallback(async () => {
    const currentErrors = formRef.current?.getErrors();

    const newValue = formRef.current?.getFieldValue('refValue');

    try {
      const schema = Yup.object().shape({
        refValue: Yup.number()
          .typeError('Valor deve ser um número')
          .positive('Valor deve ser maior que 0'),
      });

      await schema.validate({ refValue: newValue }, { abortEarly: false });

      if (currentErrors && 'refValue' in currentErrors) {
        delete currentErrors.refValue;

        formRef.current?.setErrors(currentErrors);
      }

      setBaseValue(Number(newValue));
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors({ ...errors, ...currentErrors });
        setBaseValue(0);
      }
    }
  }, []);

  const handleWeightsChange = useCallback(
    async (id: string) => {
      const currentErrors = formRef.current?.getErrors();
      const currentWeights = { ...weights };
      const newWeight = Number(formRef.current?.getFieldValue(id));

      try {
        const schema = Yup.object().shape({
          [id]: Yup.number()
            .typeError('Valor deve ser um número')
            .min(0, 'Valor do peso deve ser positivo'),
        });

        await schema.validate({ [id]: newWeight }, { abortEarly: false });

        currentWeights[id] = newWeight;
        setWeights(currentWeights);

        if (currentErrors && id in currentErrors) {
          delete currentErrors[id];

          formRef.current?.setErrors(currentErrors);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors({ ...errors, ...currentErrors });
        }
      }
    },
    [weights],
  );

  useEffect(() => {
    api.get('account').then((response) => {
      const outcomeAccounts = response.data.filter((account: AccountData) => {
        return account.type === 'OUT';
      });

      setAccounts(outcomeAccounts);

      const accountData = outcomeAccounts.reduce(
        (obj: AccountValues, account: AccountData) => ({
          ...obj,
          [account.id]: 0,
        }),
        {},
      );
      setWeights(accountData);
      setResults(accountData);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    // Calcula as porcentagens de cada quantidade
    const newResults = {};

    // eslint-disable-next-line array-callback-return
    Object.entries(weights).map(([key, value]): void => {
      const result = ((value / baseValue) * 100).toFixed(2);
      Object.assign(newResults, { [key]: result });
    });

    setResults(newResults);

    // Ajusta valor restante
    const weightSum = Object.values(weights).reduce(
      (acc, curr) => acc + curr,
      0,
    );

    if (weightSum && baseValue) {
      const result = formatValue(baseValue - weightSum);
      setAmountRemaining(result);
    } else {
      setAmountRemaining(formatValue(baseValue));
    }
  }, [weights, baseValue]);

  return loading ? (
    <Container>
      <Toolbar />
      <LoadingAnimation />
    </Container>
  ) : (
    <Container>
      <Toolbar />
      {accounts.length > 0 ? (
        <FormArea>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h2>Registro de Pesos de Contas</h2>
            <Input
              name="refValue"
              icon={FiDollarSign}
              placeholder="Valor de referência"
              onChange={handleBaseValueChange}
              lang="pt"
              pattern="[0-9]+([\.,][0-9]+)?"
              step="0.01"
              hasTip="Insira aqui o valor esperado de sua remuneração mensal (sem contar com benefícios)"
            />
            <div className="available-balance">
              <p>Valor restante para distribuir: </p>
              <div>{baseValue ? amountRemaning : 'R$ 0,00'}</div>
            </div>
            {accounts.map((account: AccountData) => {
              return (
                <WeightContainer>
                  <Input
                    key={account.id}
                    name={account.id}
                    icon={FiDollarSign}
                    placeholder={account.name}
                    onChange={() => handleWeightsChange(account.id)}
                  />
                  <div className="ref-value">
                    {baseValue ? `${results[account.id]} %` : '0.00 %'}
                  </div>
                </WeightContainer>
              );
            })}
            <Button type="submit">Registrar Pesos</Button>
          </Form>
        </FormArea>
      ) : (
        <MessageArea>
          <div>
            Parece que ainda não há contas de saída cadastradas em sua
            aplicação, ou não haviam contas cadastradas no mês selecionado.
          </div>
          <br />
          <div>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            Selecione um novo mês para consulta, ou
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <Link to="account-register"> clique aqui</Link> para fazer fazer seu
            primeiro cadastro agora mesmo!
          </div>
        </MessageArea>
      )}
    </Container>
  );
};

export default AccountWeightsRegister;
