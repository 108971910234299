import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  z-index: 10;

  width: 100%;
  height: 100px;

  background: #17171e;
  position: fixed;

  img {
    margin-left: 32px;
  }

  #dashboard {
    color: #eea302;
    margin-right: 32px;

    font-size: 24px;
    font-weight: 500;

    text-decoration: none;
  }

  #accounts {
    color: #eea302;
    margin-right: 32px;

    font-size: 24px;
    font-weight: 500;

    text-decoration: none;
  }

  #signout {
    background: transparent;
    border: none;
  }

  @media screen and (min-width: 601px) {
    .mobile {
      display: none;
    }
  }

  /* Mobile menu styles */
  @media screen and (max-width: 600px) {
    img {
      margin-left: 16px; /* Adjust as needed */
    }

    #dashboard,
    #accounts,
    .desktop {
      display: none; /* Hide links on larger screens */
    }
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  right: 0;

  margin-right: 55px;

  svg {
    color: #eea302;
  }
`;

/* New styles for the mobile menu icon */
export const MobileMenuIcon = styled.div`
  display: none; /* Initially hide on larger screens */

  @media screen and (max-width: 600px) {
    display: block; /* Show on smaller screens */
    font-size: 24px;
    cursor: pointer;
    margin-right: 16px;
  }
`;

/* New styles for the mobile menu content */
export const MobileMenu = styled.div`
  background: #17171e;
  position: fixed;
  top: 100px; /* Adjust as needed */
  right: 0;
  width: 100%;
  padding: 16px;

  a,
  button {
    display: block;
    color: #eea302;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;
