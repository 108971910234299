import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
`;

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const FormArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  animation: ${appearFromBottom} 1s;

  width: 630px;
  margin-top: 140px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  background: #17171e;
  border-radius: 10px;
  padding: 32px 0;

  .available-balance {
    display: flex;
    margin: 24px 0;
    color: #eea302;
    align-items: center;

    p {
      margin-right: 12px;
    }

    div {
      flex: 1;
      margin-left: 10px;
      font-weight: 500;
      /* padding-bottom: 4px; */
      /* border-bottom: 1px solid #eea302; */
    }
  }

  form {
    width: 370px;

    h2 {
      font-size: 32px;
      color: #7086f5;
      padding-bottom: 48px;
    }

    input {
      width: 280px;
    }
  }
`;

export const WeightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  input {
    max-width: 80%;
  }

  div {
    margin: 0;
    max-width: 60%;
  }

  .ref-value {
    flex: 1;
    margin-left: 10px;
    padding-bottom: 4px;
    border-bottom: 1px solid #b8b8b8;
  }
`;

export const MessageArea = styled.div`
  width: 630px;
  height: 300px;
  margin-top: 140px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #17171e;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 18px;

  a {
    color: #eea302;
  }
`;
