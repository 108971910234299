import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;

  input {
    margin-left: 10px;
    background: #17171e;
    border: 0;
    color: #b3b3b3;
    font-size: 18px;
    font-weight: 500;

    max-width: 100px;
  }

  .react-datepicker {
    border: 1px solid #17171e;

    &__triangle::before,
    &__triangle {
      border-bottom-color: #2e2e35 !important;
    }

    &__navigation--previous {
      border-right-color: #eea302;
    }

    &__navigation--next {
      border-left-color: #eea302;
    }

    &__month-container {
      background: #2e2e35;
      color: #eea302;
      font-weight: 500;
    }

    &__header {
      background: #2e2e35;
      color: #eea302;
      padding: 8px;
    }

    &__current-month,
    &__day-name,
    &__day {
      color: #eea302;
    }

    &__day--selected {
      background: #eea302;
      color: #000008;
      &.react-datepicker__day--keyboard-selected {
        background: #eea302;
        color: #000008;
      }
    }

    &__day--keyboard-selected {
      background-color: #2e2e35;
      color: #eea302;
    }

    &__day--focus {
      background: #eea302;
      color: #000008;
    }

    &__day {
      &:hover {
        background: #b3b3b3;
        color: #000008;
      }
    }
  }

  svg {
    color: #eea302;
    margin-left: 10px;
  }
`;
