import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;

  .deleteModal {
    background: #17171e;
    max-width: 600px;
    width: 35%;
    padding: 48px 32px 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px;

    form {
      max-width: 80%;
    }

    h3 {
      color: #eea302;
      padding-bottom: 32px;
      text-align: center;
    }

    .submitDeleteTransaction {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      button {
        max-width: 48%;
      }
    }
  }

  .editSavingsAccountModal {
    background: #17171e;
    max-width: 500px;
    width: 30%;
    padding: 40px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px;

    form {
      max-width: 80%;
    }

    h2 {
      text-align: center;
      font-size: 32px;
      color: #7086f5;
      padding-bottom: 32px;
    }

    h3 {
      color: #eea302;
      padding-bottom: 32px;
    }

    input {
      width: 300px;
    }

    .submitSavingsAccount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      button {
        max-width: 48%;
      }
    }
  }
`;

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AccountsArea = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  animation: ${appearFromBottom} 1s;

  width: 90%;
  margin: 140px 5%;
  padding: 56px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  background: #17171e;
  border-radius: 10px;

  h2 {
    font-size: 32px;
    color: #7086f5;
    padding-bottom: 48px;
  }

  a {
    color: #eea302;
  }
`;

export const Subtitle = styled.div`
  display: flex;

  font-size: 24px;
  font-weight: 500;
  color: #7086f5;

  margin-bottom: 16px;
`;

export const AccountsTable = styled.table`
  border-spacing: 0 16px;
  margin-bottom: 56px;

  tr {
    background: #a2a2a5;
    color: #000000;
    text-align: justify;

    th {
      &:first-child {
        max-width: 50px;
      }

      background: #17171e;
      color: #b8b8b8;
      font-size: 22px;
      font-weight: 500;
      text-align: center;

      margin-left: 32px;

      border: 0;
    }

    td {
      &:first-child {
        border-radius: 5px 0 0 5px;
        width: 18%;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }

      padding: 8px 0;
      text-align: center;
      /* width: 25%; */
      width: 38%;
      font-size: 18px;

      button {
        background: #a2a2a5;
        border: 0;

        svg {
          margin: 8px;
        }
      }
    }

    .lastChildEdit {
      width: 64px;
      border-radius: 0 5px 5px 0;
      border-left: 2px solid #000008;
      padding: 0 8px;
    }
  }

  .positivo {
    color: #006000;
  }

  .negativo {
    color: #c80800;
  }
`;

export const DeleteFormData = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  /* align-items: baseline; */

  .deleteRow {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;

    .deleteLabel {
      width: 24%;
      margin-right: 24px;
      font-weight: 500;
      font-size: 18px;
    }

    .deleteData {
      flex: 1;
      border-bottom: 1px solid white;
      text-align: center;
      background: transparent;
      color: #b8b8b8;
      padding-bottom: 4px;
      font-size: 18px;
    }
  }
`;
