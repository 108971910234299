/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  FiAlertCircle,
  FiEdit,
  FiTrash2,
  FiCreditCard,
  FiDollarSign,
  FiList,
  FiChevronDown,
} from 'react-icons/fi';
import { Form as UnformForm } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Modal } from 'react-responsive-modal';

import 'react-responsive-modal/styles.css';

import { Link } from 'react-router-dom';
import {
  Container,
  SummaryArea,
  Form,
  Subtitle,
  TransactionsTable,
  TableFilterButton,
  TransfersTable,
  DeleteFormData,
  TableHeaderText,
  PriorityExpenseText,
} from './styles';
import {
  AccountData,
  AccountsSelectOptions,
  FilterOptions,
  ModalData,
  SavingsAccountData,
  Transaction,
  Transfer,
  TransferResponseData,
  UpdateTransactionData,
  UpdateTransferData,
  Filters,
  CheckboxOption,
} from './interfaces';

import Toolbar from '../../components/Toolbar';
import MonthPicker from '../../components/MonthPicker';
import DayPicker from '../../components/DayPicker';
import Tooltip from '../../components/Tooltip';
import Input from '../../components/Input';
import Button from '../../components/Button';
import SwitchComponent from '../../components/SwitchComponent';
import Select from '../../components/SelectComponent';
import LoadingAnimation from '../../components/Loading';
import TableListFilter from '../../components/TableListFilter';
import CheckboxInput from '../../components/CheckBox';

import api from '../../services/api';

import formatValue from '../../utils/formatValue';
import { formatDate, parseDateStr } from '../../utils/formatDate';

const TransactionsSummary: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [processedTransactions, setProcessedTransactions] = useState(false);
  const [processedTransfers, setProcessedTransfers] = useState(false);

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [accounts, setAccounts] = useState<AccountData[]>([]);
  const [savingsAccounts, setSavingsAccounts] = useState<SavingsAccountData[]>(
    [],
  );
  const [originalTransactions, setOriginalTransactions] = useState<
    Transaction[]
  >([]);
  const [transfers, setTransfers] = useState<Transfer[]>([]);
  const [priorityExpenses, setPriorityExpenses] = useState('');

  const [date, setDate] = useState(null);

  // ############################## Modal data ###############################
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [isDeleteTransferModalOpen, setIsDeleteTransferModalOpen] = useState(
    false,
  );
  const [
    isDeleteTransactionModalOpen,
    setIsDeleteTransactionModalOpen,
  ] = useState(false);
  const [editTransaction, setEditTransaction] = useState<Transaction | null>();
  const [
    deleteTransaction,
    setDeleteTransaction,
  ] = useState<Transaction | null>();
  const [editTransfer, setEditTransfer] = useState<Transfer | null>();
  const [deleteTransfer, setDeleteTransfer] = useState<Transfer | null>();

  const [
    combinedAccountsSelectOptions,
    setCombinedAccountsSelectOptions,
  ] = useState<AccountsSelectOptions[]>([]);

  const [
    outcomeAccountsSelectOptions,
    setOutcomeAccountsSelectOptions,
  ] = useState<AccountsSelectOptions[]>([]);

  const [
    savingsAccountsSelectOptions,
    setSavingsAccountsSelectOptions,
  ] = useState<AccountsSelectOptions[]>([]);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const checkboxOptions: CheckboxOption[] = [
    { id: 'priority-expense', value: 'true', label: 'Gasto prioritário' },
  ];

  const handleCheckbox = useCallback(() => {
    setIsCheckboxChecked(!isCheckboxChecked);
  }, [isCheckboxChecked]);
  // ############################## Modal data ###############################

  // ############################## Filters data ###############################
  const [isAccountFilterOpen, setIsAccountFilterOpen] = useState(false);
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const [isTypeFilterOpen, setIsTypeFilterOpen] = useState(false);
  const [isAmountFilterOpen, setIsAmountFilterOpen] = useState(false);
  const [isNameFilterOpen, setIsNameFilterOpen] = useState(false);
  const [isDescriptionFilterOpen, setIsDescriptionFilterOpen] = useState(false);
  const [isPriotityFilterOpen, setIsPriotityFilterOpen] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    type: [],
    date: [],
    account: [],
    amount: [],
    name: [],
    description: [],
    priority: [],
  });
  // ############################## Filters data ###############################

  // Edit transaction modal variable for setting the accounts
  const [opType, setOpType] = useState(0);
  const setNewFilter = useCallback(() => {
    const switchOption = opType;

    setOpType(switchOption === 1 ? 0 : 1);
  }, [opType]);

  const containerRef = useRef<HTMLDivElement>(null);
  const dateFormRef = useRef<FormHandles>(null);
  const transactionModalFormRef = useRef<FormHandles>(null);
  const transferModalFormRef = useRef<FormHandles>(null);

  const handleOpenModal = useCallback(
    ({ modalName, transactionModalData, transferModalData }: ModalData) => {
      switch (modalName) {
        case 'transfer':
          setIsTransferModalOpen(true);
          setEditTransfer(transferModalData);
          break;
        case 'transaction':
          setIsTransactionModalOpen(true);
          setEditTransaction(transactionModalData);
          if (transactionModalData)
            setOpType(transactionModalData?.transaction_type === 'IN' ? 0 : 1);
          break;
        case 'deleteTransaction':
          setIsDeleteTransactionModalOpen(true);
          setDeleteTransaction(transactionModalData);
          break;
        case 'deleteTransfer':
          setIsDeleteTransferModalOpen(true);
          setDeleteTransfer(transferModalData);
          break;
        default:
          console.log('Especificar tipo de modal');
      }
    },
    [],
  );

  const handleCloseModal = useCallback((modalName) => {
    switch (modalName) {
      case 'transfer':
        setIsTransferModalOpen(false);
        setEditTransfer(null);
        break;
      case 'transaction':
        setIsTransactionModalOpen(false);
        setEditTransaction(null);
        break;
      case 'deleteTransaction':
        setIsDeleteTransactionModalOpen(false);
        setDeleteTransaction(null);
        break;
      case 'deleteTransfer':
        setIsDeleteTransferModalOpen(false);
        setDeleteTransfer(null);
        break;
      default:
        console.log('Especificar tipo de modal');
    }
  }, []);

  const handleDateChange = useCallback((compDate) => {
    setDate(compDate);
  }, []);

  const handleUpdateTransaction = useCallback(
    async (data: UpdateTransactionData) => {
      // Subtracts 1 hour to get the actual date without converting it to the next day
      const transactionDate = new Date(data.date);

      const updatedTransactionData = {
        id: editTransaction?.id,
        date: formatDate(transactionDate),
        transaction_type: data.opType ? 'OUT' : 'IN',
        account_type: data.expenseOrigin.type,
        amount: Math.round(Number(data.amount * 100)),
        account_id: data.expenseOrigin.id,
        location: data.location,
        description: data.description,
        priority_expense: data.priorityExpense.length > 0,
      };

      console.log(updatedTransactionData);

      await api.put<Transaction>('transaction', updatedTransactionData);

      setIsTransactionModalOpen(false);
      // force scrren to reload and fetch updated transactions
      setDate(transactionDate as any);
      setEditTransaction(null);
    },
    [editTransaction],
  );

  const handleUpdateTransfer = useCallback(
    async (data: UpdateTransferData) => {
      const transferDate = new Date(data.transferDate);
      transferDate.setHours(transferDate.getHours() - 1);

      const updatedTransferData = {
        transfer_id: editTransfer?.id,
        date: formatDate(transferDate),
        origin_id: data.accountOriginID,
        destination_id: data.destinationSavingsAccountID,
        amount: Math.round(data.transferAmount * 100),
      };

      await api.put<TransferResponseData>('transfer', updatedTransferData);

      setIsTransferModalOpen(false);
      setEditTransfer(null);
      // force scrren to reload and fetch updated transactions
      setDate(transferDate as any);
    },
    [editTransfer],
  );

  const handleDeleteTransaction = useCallback(
    async (
      transactionId: string,
      accountType: string,
      transactionDate: string,
    ) => {
      const formattedDate = transactionDate.split('/').join('-');
      await api.delete(`transaction\\${transactionId}`, {
        params: {
          type: accountType,
          date: formattedDate,
        },
      });

      const currentTransactions = transactions.filter((transaction) => {
        return transaction.id !== transactionId;
      });

      setTransactions(currentTransactions);
      setDeleteTransaction(null);
      setIsDeleteTransactionModalOpen(false);
    },
    [transactions],
  );

  const handleDeleteTransfer = useCallback(
    async (transfer_id: string, transferDate: string) => {
      const payload = {
        transfer_id,
        date: transferDate,
      };

      await api.delete('transfer', {
        data: payload,
      });

      const currentTransfers = transfers.filter((transfer) => {
        return transfer.id !== transfer_id;
      });

      const currentTransactions = transactions.filter((transaction) => {
        return transaction.transfer_id !== transfer_id;
      });

      setTransfers(currentTransfers);
      setTransactions(currentTransactions);
      setDeleteTransfer(null);
      setIsDeleteTransferModalOpen(false);
    },
    [transfers, transactions],
  );

  // ########################## Filters content ##########################
  const handleOpenFilter = useCallback((filterName: string) => {
    switch (filterName) {
      case 'accountFilter':
        setIsAccountFilterOpen((previousState) => !previousState);
        break;
      case 'dateFilter':
        setIsDateFilterOpen((previousState) => !previousState);
        break;
      case 'typeFilter':
        setIsTypeFilterOpen((previousState) => !previousState);
        break;
      case 'amountFilter':
        setIsAmountFilterOpen((previousState) => !previousState);
        break;
      case 'nameFilter':
        setIsNameFilterOpen((previousState) => !previousState);
        break;
      case 'descriptionFilter':
        setIsDescriptionFilterOpen((previousState) => !previousState);
        break;
      case 'priorityFilter':
        setIsPriotityFilterOpen((previousState) => !previousState);
        break;
      default:
        console.log('Especificar tipo de filtro');
    }
  }, []);

  const handleGetFilters = useCallback(
    (filterOptions: FilterOptions[], filterKey: string) => {
      const currentFilters = filters;
      switch (filterKey) {
        case 'account':
          currentFilters.account = filterOptions;
          break;
        case 'type':
          currentFilters.type = filterOptions;
          break;
        case 'date':
          currentFilters.date = filterOptions;
          break;
        case 'amount':
          currentFilters.amount = filterOptions;
          break;
        case 'name':
          currentFilters.name = filterOptions;
          break;
        case 'description':
          currentFilters.description = filterOptions;
          break;
        case 'priority':
          currentFilters.priority = filterOptions;
          break;
        default:
          console.log('Selecione um filtro presente no objeto "filters"');
      }
      setFilters(currentFilters);
      setIsAccountFilterOpen(false);
      setIsDateFilterOpen(false);
      setIsTypeFilterOpen(false);
      setIsAmountFilterOpen(false);
      setIsNameFilterOpen(false);
      setIsDescriptionFilterOpen(false);
      setIsPriotityFilterOpen(false);
    },
    [filters],
  );

  const getFilterOptions = useCallback(
    (key: string, filter: FilterOptions[]) => {
      if (!filter.some((f) => f.value === key)) {
        filter.push({
          value: key,
          checked: true,
        });
      }
      return filter;
    },
    [],
  );
  // ########################## Filters content ##########################

  // Fetch accounts and savings accouts
  useEffect(() => {
    api.get<AccountData[]>('account').then((response) => {
      const outcomeAccountsOptions = response.data
        .filter((account) => {
          return account.type === 'OUT' && !account.deleted_at;
        })
        .map((selectOption) => {
          return { value: selectOption, label: selectOption.name };
        });

      setOutcomeAccountsSelectOptions(outcomeAccountsOptions);
      setAccounts(response.data);
    });

    api.get<SavingsAccountData[]>('savings-account').then((response) => {
      setSavingsAccounts(response.data);
    });
  }, [date]);

  // Fetch transactions and transfers
  useEffect(() => {
    const currDate = date || new Date();

    let month: any;
    month = currDate.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    const year = currDate.getFullYear();
    api
      .get<Transaction[]>('transaction', {
        params: {
          month,
          year,
        },
      })
      .then((response) => {
        const formattedData = response.data
          .sort((a: Transaction, b: Transaction) => {
            const dateA = parseDateStr('/', a.date);
            const dateB = parseDateStr('/', b.date);

            return dateA.getTime() - dateB.getTime();
          })
          .map((transaction: Transaction) => {
            const currentDate = parseDateStr('/', transaction.date);

            const formattedAmount = formatValue(transaction.amount / 100);
            const formattedDate = formatDate(currentDate);
            let formattedDescription: string;

            if (transaction.description.length > 50) {
              formattedDescription = `${transaction.description.substr(
                0,
                47,
              )}...`;
            } else {
              formattedDescription =
                transaction.description === '' ? '-' : transaction.description;
            }

            let accName;
            if (transaction.account_type === 'SAVINGS') {
              const savingsAcc = savingsAccounts.find(
                (acc) => acc.id === transaction.account_id,
              );

              accName = savingsAcc ? savingsAcc.name : 'account not found';
            } else {
              const account = accounts.find(
                (acc) => acc.id === transaction.account_id,
              );

              accName = account ? account.name : 'account not found';
            }

            return {
              formattedAmount,
              formattedDate,
              formattedDescription,
              accountName: accName,
              ...transaction,
            };
          });

        const priorityTransactions = formattedData.reduce((acc, currentVal) => {
          if (
            currentVal.transaction_type === 'OUT' &&
            currentVal.priority_expense
          ) {
            return acc + currentVal.amount;
          }
          return acc;
        }, 0);

        setTransactions(formattedData);
        setOriginalTransactions(formattedData);
        setProcessedTransactions(true);
        setPriorityExpenses(formatValue(priorityTransactions / 100));
      });

    api
      .get<Transfer[]>('transfer', {
        params: {
          month,
          year,
        },
      })
      .then((response) => {
        const formattedData = response.data
          .sort((a: Transfer, b: Transfer) => {
            const dateA = parseDateStr('/', a.date);
            const dateB = parseDateStr('/', b.date);

            return dateA.getTime() - dateB.getTime();
          })
          .map((transfer: Transfer) => {
            const currentDate = parseDateStr('/', transfer.date);

            const formattedAmount = formatValue(transfer.amount / 100);
            const formattedDate = formatDate(currentDate);

            const account = accounts.find(
              (acc) => acc.id === transfer.origin_account_id,
            );
            const originName = account ? account.name : 'account not found';

            const savingsAcc = savingsAccounts.find(
              (acc) => acc.id === transfer.destination_savings_account_id,
            );
            const destinationName = savingsAcc
              ? savingsAcc.name
              : 'account not found';

            return {
              formattedAmount,
              formattedDate,
              originName,
              destinationName,
              ...transfer,
            };
          });
        setTransfers(formattedData);
        setProcessedTransfers(true);
      });
  }, [date, accounts, savingsAccounts]);

  // Crate the filter object
  useEffect(() => {
    const filterObject: Filters = {
      type: [],
      date: [],
      account: [],
      city: [],
      amount: [],
      name: [],
      description: [],
      priority: [],
    };

    originalTransactions.forEach((transaction: Transaction) => {
      if (transaction.accountName) {
        getFilterOptions(transaction.accountName, filterObject.account);
      }
      if (transaction.formattedDate) {
        getFilterOptions(transaction.formattedDate, filterObject.date);
      }
      getFilterOptions(
        transaction.transaction_type === 'OUT' ? 'Saída' : 'Entrada',
        filterObject.type,
      );
      if (transaction.formattedAmount) {
        getFilterOptions(transaction.formattedAmount, filterObject.amount);
      }
      getFilterOptions(transaction.location, filterObject.name);
      getFilterOptions(transaction.description, filterObject.description);
      getFilterOptions(
        transaction.priority_expense === true ? 'Sim' : 'Não',
        filterObject.priority,
      );
    });
    setFilters(filterObject);
  }, [originalTransactions, getFilterOptions]);

  // Finish loading animation
  useEffect(() => {
    if (processedTransfers && processedTransactions) {
      setLoading(false);
    }
  }, [processedTransactions, processedTransfers]);

  // Filter the transactions
  useEffect(() => {
    const dateFilteredTransactions = originalTransactions.filter(
      (transaction) => {
        if (transaction.formattedDate) {
          if (
            filters.date.some(
              (f) => f.value === transaction.formattedDate && f.checked,
            )
          ) {
            return transaction;
          }
        }

        return false;
      },
    );

    const accountFilteredTransactions = dateFilteredTransactions.filter(
      (transaction) => {
        if (
          filters.account.some(
            (f) => f.value === transaction.accountName && f.checked,
          )
        ) {
          return transaction;
        }

        return false;
      },
    );

    const typeFilteredTransactions = accountFilteredTransactions.filter(
      (transaction) => {
        const transactionType =
          transaction.transaction_type === 'OUT' ? 'Saída' : 'Entrada';
        if (
          filters.type.some((f) => f.value === transactionType && f.checked)
        ) {
          return transaction;
        }

        return false;
      },
    );

    const amountFilteredTransactions = typeFilteredTransactions.filter(
      (transaction) => {
        if (
          filters.amount.some(
            (f) => f.value === transaction.formattedAmount && f.checked,
          )
        ) {
          return transaction;
        }

        return false;
      },
    );

    const nameFilteredTransactions = amountFilteredTransactions.filter(
      (transaction) => {
        if (
          filters.name.some(
            (f) => f.value === transaction.location && f.checked,
          )
        ) {
          return transaction;
        }

        return false;
      },
    );

    const descriptionFilteredTransactions = nameFilteredTransactions.filter(
      (transaction) => {
        if (
          filters.description.some(
            (f) => f.value === transaction.description && f.checked,
          )
        ) {
          return transaction;
        }

        return false;
      },
    );

    const priorityFilteredTransactions = descriptionFilteredTransactions.filter(
      (transaction) => {
        const priority = transaction.priority_expense ? 'Sim' : 'Não';
        if (filters.priority.some((f) => f.value === priority && f.checked)) {
          return transaction;
        }

        return false;
      },
    );

    setTransactions(priorityFilteredTransactions);
    setIsAccountFilterOpen(false);
  }, [
    originalTransactions,
    filters,
    filters.account,
    filters.date,
    filters.type,
    filters.city,
    filters.name,
    filters.description,
    filters.amount,
    filters.priority,
  ]);

  return loading ? (
    <Container>
      <Toolbar />
      <LoadingAnimation />
    </Container>
  ) : (
    <Container ref={containerRef}>
      <Toolbar />
      {isTransactionModalOpen && (
        <Modal
          open={isTransactionModalOpen}
          showCloseIcon={false}
          onClose={() => handleCloseModal('transaction')}
          center
          classNames={{
            modal: 'editTransactionModal',
          }}
          container={containerRef.current as Element}
        >
          <UnformForm
            ref={transactionModalFormRef}
            onSubmit={handleUpdateTransaction}
          >
            <h2>Editar transação</h2>
            <SwitchComponent
              name="opType"
              options={['Entrada', 'Saída']}
              id="op-type"
              clickEvent={setNewFilter}
              isChecked={editTransaction?.transaction_type === 'OUT'}
              isDisabled
            />
            <DayPicker
              name="date"
              defaultDate={parseDateStr('/', editTransaction?.date)}
            />
            <CheckboxInput
              infoTitle="Gastos prioritários são somados e mostrados por mês na tela de transações."
              name="priorityExpense"
              options={checkboxOptions}
              onChange={handleCheckbox}
              defaultChecked={editTransaction?.priority_expense}
            />
            <Select
              name="expenseOrigin"
              icon={FiCreditCard}
              placeholder="Conta"
              selectOptions={combinedAccountsSelectOptions}
              defaultValue={{
                value: {
                  id: editTransaction?.account_id,
                  type: editTransaction?.account_type,
                  name: editTransaction?.accountName,
                },
                label: editTransaction?.accountName,
              }}
              isDisabled
            />
            <Input
              name="location"
              icon={FiList}
              placeholder="Local"
              defaultValue={editTransaction?.location}
            />
            <Input
              name="amount"
              icon={FiDollarSign}
              placeholder="Valor"
              defaultValue={editTransaction ? editTransaction.amount / 100 : 0}
            />
            <Input
              name="description"
              icon={FiList}
              placeholder="Descrição"
              defaultValue={editTransaction?.description}
            />
            <div className="submitTransaction">
              <Button
                type="button"
                onClick={() => handleCloseModal('transaction')}
              >
                Cancelar
              </Button>
              <Button type="submit">Salvar alterações</Button>
            </div>
          </UnformForm>
        </Modal>
      )}
      {isTransferModalOpen && (
        <Modal
          open={isTransferModalOpen}
          showCloseIcon={false}
          onClose={() => handleCloseModal('transfer')}
          center
          classNames={{
            modal: 'editTransactionModal',
          }}
          container={containerRef.current as Element}
        >
          <UnformForm
            ref={transferModalFormRef}
            onSubmit={handleUpdateTransfer}
          >
            <h2>Editar transferência</h2>
            <DayPicker
              name="transferDate"
              defaultDate={parseDateStr('/', editTransfer?.date)}
            />
            <Select
              name="accountOrigin"
              icon={FiCreditCard}
              placeholder="Conta"
              selectOptions={outcomeAccountsSelectOptions}
              defaultValue={{
                value: editTransfer?.origin_account_id,
                label: editTransfer?.originName,
              }}
            />
            <Select
              name="savingsAccountDestination"
              icon={FiCreditCard}
              placeholder="Poupança"
              selectOptions={savingsAccountsSelectOptions}
              defaultValue={{
                value: editTransfer?.destination_savings_account_id,
                label: editTransfer?.destinationName,
              }}
            />
            <Input
              name="transferAmount"
              icon={FiDollarSign}
              placeholder="Valor"
              defaultValue={editTransfer ? editTransfer.amount / 100 : 0}
            />
            <div className="submitTransaction">
              <Button type="submit">Salvar alterações</Button>
              <Button
                type="button"
                onClick={() => handleCloseModal('transfer')}
              >
                Cancelar
              </Button>
            </div>
          </UnformForm>
        </Modal>
      )}
      {isDeleteTransactionModalOpen && (
        <Modal
          open={isDeleteTransactionModalOpen}
          showCloseIcon={false}
          onClose={() => handleCloseModal('deleteTransaction')}
          center
          classNames={{
            modal: 'deleteModal',
          }}
          container={containerRef.current as Element}
        >
          <h3>Tem certeza que deseja deletar a seguinte transação?</h3>
          <DeleteFormData>
            <div className="deleteRow">
              <div className="deleteLabel">Data:</div>
              <div className="deleteData">{deleteTransaction?.date}</div>
            </div>
            <div className="deleteRow">
              <div className="deleteLabel">Conta:</div>
              <div className="deleteData">{deleteTransaction?.accountName}</div>
            </div>
            <div className="deleteRow">
              <div className="deleteLabel">Valor:</div>
              <div className="deleteData">
                {deleteTransaction?.formattedAmount}
              </div>
            </div>
            <div className="deleteRow">
              <div className="deleteLabel">Local:</div>
              <div className="deleteData">{deleteTransaction?.location}</div>
            </div>
            <div className="deleteRow">
              <div className="deleteLabel">Description:</div>
              <div className="deleteData">{deleteTransaction?.description}</div>
            </div>
          </DeleteFormData>
          <div className="submitDeleteTransaction">
            <Button
              type="button"
              onClick={() => handleCloseModal('deleteTransaction')}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              onClick={() => {
                if (deleteTransaction?.id) {
                  handleDeleteTransaction(
                    deleteTransaction?.id,
                    deleteTransaction.account_type,
                    deleteTransaction.date,
                  );
                }
              }}
            >
              Excluir transação
            </Button>
          </div>
        </Modal>
      )}
      {isDeleteTransferModalOpen && (
        <Modal
          open={isDeleteTransferModalOpen}
          showCloseIcon={false}
          onClose={() => handleCloseModal('deleteTransfer')}
          center
          classNames={{
            modal: 'deleteModal',
          }}
          container={containerRef.current as Element}
        >
          <h3>Tem certeza que deseja deletar a seguinte transferência?</h3>
          <DeleteFormData>
            <div className="deleteRow">
              <div className="deleteLabel">Data:</div>
              <div className="deleteData">{deleteTransfer?.formattedDate}</div>
            </div>
            <div className="deleteRow">
              <div className="deleteLabel">Conta:</div>
              <div className="deleteData">{deleteTransfer?.originName}</div>
            </div>
            <div className="deleteRow">
              <div className="deleteLabel">Poupança:</div>
              <div className="deleteData">
                {deleteTransfer?.destinationName}
              </div>
            </div>
            <div className="deleteRow">
              <div className="deleteLabel">Valor:</div>
              <div className="deleteData">
                {deleteTransfer?.formattedAmount}
              </div>
            </div>
          </DeleteFormData>
          <div className="submitDeleteTransaction">
            <Button
              type="submit"
              onClick={() => {
                if (deleteTransfer?.id) {
                  handleDeleteTransfer(deleteTransfer?.id, deleteTransfer.date);
                }
              }}
            >
              Excluir transferência
            </Button>
            <Button
              type="button"
              onClick={() => handleCloseModal('deleteTransfer')}
            >
              Cancelar
            </Button>
          </div>
        </Modal>
      )}
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <Form ref={dateFormRef} onSubmit={() => {}}>
        <MonthPicker name="month" clickEvent={handleDateChange} />
      </Form>
      <PriorityExpenseText>
        Gastos prioritários no mês:
        {priorityExpenses}
      </PriorityExpenseText>
      <SummaryArea>
        {originalTransactions.length > 0 || transfers.length > 0 ? (
          <>
            <Subtitle>Transações</Subtitle>
            <TransactionsTable>
              <tr>
                <th>
                  <TableHeaderText>
                    <TableFilterButton
                      type="button"
                      onClick={
                        () => handleOpenFilter('typeFilter')
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    >
                      Tipo
                      <FiChevronDown />
                    </TableFilterButton>
                  </TableHeaderText>
                  {isTypeFilterOpen && (
                    <TableListFilter
                      title="Filtar tipos"
                      filterOptions={filters.type}
                      filterType="type"
                      getFilters={handleGetFilters}
                    />
                  )}
                </th>
                <th>
                  <TableHeaderText>
                    <TableFilterButton
                      type="button"
                      onClick={
                        () => handleOpenFilter('priorityFilter')
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    >
                      Prioridade
                      <FiChevronDown />
                    </TableFilterButton>
                  </TableHeaderText>
                  {isPriotityFilterOpen && (
                    <TableListFilter
                      title="Filtar prioridades"
                      filterOptions={filters.priority}
                      filterType="priority"
                      getFilters={handleGetFilters}
                    />
                  )}
                </th>
                <th>
                  <TableFilterButton
                    type="button"
                    onClick={
                      () => handleOpenFilter('dateFilter')
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    Data
                    <FiChevronDown />
                  </TableFilterButton>
                  {isDateFilterOpen && (
                    <TableListFilter
                      title="Filtar datas"
                      filterOptions={filters.date}
                      filterType="date"
                      getFilters={handleGetFilters}
                    />
                  )}
                </th>
                <th>
                  <TableFilterButton
                    type="button"
                    onClick={
                      () => handleOpenFilter('accountFilter')
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    Conta
                    <FiChevronDown />
                  </TableFilterButton>
                  {isAccountFilterOpen && (
                    <TableListFilter
                      title="Filtar contas"
                      filterOptions={filters.account}
                      filterType="account"
                      getFilters={handleGetFilters}
                    />
                  )}
                </th>
                <th>
                  <TableFilterButton
                    type="button"
                    onClick={
                      () => handleOpenFilter('amountFilter')
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    Valor
                    <FiChevronDown />
                  </TableFilterButton>
                  {isAmountFilterOpen && (
                    <TableListFilter
                      title="Filtar valores"
                      filterOptions={filters.amount}
                      filterType="amount"
                      getFilters={handleGetFilters}
                    />
                  )}
                </th>
                <th>
                  <TableFilterButton
                    type="button"
                    onClick={
                      () => handleOpenFilter('nameFilter')
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    Local
                    <FiChevronDown />
                  </TableFilterButton>
                  {isNameFilterOpen && (
                    <TableListFilter
                      title="Filtar nomes"
                      filterOptions={filters.name}
                      filterType="name"
                      getFilters={handleGetFilters}
                    />
                  )}
                </th>
                <th>
                  <TableFilterButton
                    type="button"
                    onClick={
                      () => handleOpenFilter('descriptionFilter')
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    Descrição
                    <FiChevronDown />
                  </TableFilterButton>
                  {isDescriptionFilterOpen && (
                    <TableListFilter
                      title="Filtar decrições"
                      filterOptions={filters.description}
                      filterType="description"
                      getFilters={handleGetFilters}
                    />
                  )}
                </th>
              </tr>
              {transactions.length > 0 ? (
                transactions.map((transaction) => {
                  return (
                    <tr key={transaction.id}>
                      <td>
                        {transaction.transaction_type === 'IN'
                          ? 'Entrada'
                          : 'Saída'}
                      </td>
                      <td className="priorityRow">
                        {transaction.priority_expense ? 'Sim' : 'Não'}
                      </td>
                      <td className="dateRow">{transaction.formattedDate}</td>
                      <td className="accountRow">{transaction.accountName}</td>
                      <td
                        className={
                          transaction.transaction_type === 'IN'
                            ? 'entrada'
                            : 'saida'
                        }
                      >
                        {transaction.formattedAmount}
                      </td>
                      <td className="transactionName">
                        {transaction.location === ''
                          ? '-'
                          : transaction.location}
                      </td>

                      {transaction.transfer_id ? (
                        <>
                          <td className="lastChild">
                            {transaction.formattedDescription}
                          </td>
                          <td className="tooltip">
                            <Tooltip title="Transferências entre contas devem ser editadas na sessão abaixo">
                              <FiAlertCircle color="#eea302" size={18} />
                            </Tooltip>
                          </td>
                        </>
                      ) : (
                        <>
                          <td title={transaction.description}>
                            {transaction.formattedDescription}
                          </td>
                          <td className="lastChildEdit">
                            <button
                              type="submit"
                              onClick={
                                () =>
                                  handleOpenModal({
                                    modalName: 'transaction',
                                    transactionModalData: transaction,
                                  })
                                // eslint-disable-next-line react/jsx-curly-newline
                              }
                            >
                              <FiEdit title="Editar transação" />
                            </button>
                            <button
                              type="submit"
                              onClick={
                                () =>
                                  handleOpenModal({
                                    modalName: 'deleteTransaction',
                                    transactionModalData: transaction,
                                  })
                                // eslint-disable-next-line react/jsx-curly-newline
                              }
                            >
                              <FiTrash2
                                color="#c80800"
                                title="Excluir transação"
                              />
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td> - </td>
                  <td className="dataRow"> - </td>
                  <td className="dataRow"> - </td>
                  <td className="dataRow"> - </td>
                  <td> - </td>
                  <td> - </td>
                  <td className="lastChild"> - </td>
                </tr>
              )}
            </TransactionsTable>
            {transfers.length > 0 && (
              <>
                <Subtitle>Transferências</Subtitle>
                <TransfersTable>
                  <tr>
                    <th>Data</th>
                    <th>Conta de Origem</th>
                    <th>Poupança Destino</th>
                    <th>Valor Transferido</th>
                  </tr>
                  {transfers.map((transfer) => {
                    return (
                      <tr>
                        <td className="dataTransferRow">
                          {transfer.formattedDate}
                        </td>
                        <td className="dataTransferRow">
                          {transfer.originName}
                        </td>
                        <td className="dataTransferRow">
                          {transfer.destinationName}
                        </td>
                        <td className="dataTransferRow">
                          {transfer.formattedAmount}
                        </td>
                        <td className="lastChildEdit">
                          <button
                            type="submit"
                            onClick={
                              () =>
                                handleOpenModal({
                                  modalName: 'transfer',
                                  transferModalData: transfer,
                                })
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          >
                            <FiEdit title="Editar transação" />
                          </button>
                          <button
                            type="submit"
                            onClick={
                              () =>
                                handleOpenModal({
                                  modalName: 'deleteTransfer',
                                  transferModalData: transfer,
                                })
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          >
                            <FiTrash2
                              color="#c80800"
                              title="Excluir transação"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </TransfersTable>
              </>
            )}
          </>
        ) : (
          <>
            <div>Parece que ainda não há transações registradas nesse mês.</div>
            <br />
            <div>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <Link to="transactions">Clique aqui</Link> para fazer fazer seu
              primeiro registro agora mesmo!
            </div>
          </>
        )}
      </SummaryArea>
    </Container>
  );
};

export default TransactionsSummary;
