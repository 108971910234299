import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;

  background: transparent;
  border: 0;

  margin-right: 24px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    border: 0;
    color: #eea302;

    span {
      margin-right: 8px;

      font-size: 24px;
      font-weight: 500;
    }
  }

  &:hover div {
    display: block;
    background: #17171e;
  }
`;
