/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal } from 'react-responsive-modal';

import { Link } from 'react-router-dom';
import { Form as UnformForm } from '@unform/web';
import { FormHandles } from '@unform/core';

import { FiEdit, FiList, FiTrash2 } from 'react-icons/fi';
import {
  Container,
  AccountsArea,
  Subtitle,
  AccountsTable,
  DeleteFormData,
} from './styles';

import Toolbar from '../../components/Toolbar';
import LoadingAnimation from '../../components/Loading';

import api from '../../services/api';
import formatValue from '../../utils/formatValue';
import Input from '../../components/Input';
import Button from '../../components/Button';

interface AccountData {
  id: string;
  name: string;
  type: string;
  active: boolean;
  weight: number;
  formattedWeight?: string;
}

interface SavingsAccountData {
  id: string;
  name: string;
  description: string;
  goal: number;
  type: string;
  active?: boolean;
  initial_balance?: number;
  formatted_goal: string;
}

interface ModalData {
  modalName: string;
  accountData?: AccountData;
  savingsAccountData?: SavingsAccountData;
}

interface EditSavingsAccountData {
  name: string;
  description: string;
  goal: number;
}

const Accounts: React.FC = () => {
  const [accounts, setAccounts] = useState<AccountData[]>([]);
  const [savingsAccounts, setSavingsAccounts] = useState<SavingsAccountData[]>(
    [],
  );

  const [loading, setLoading] = useState(true);
  const [processedSavingsAccounts, setProcessedSavingsAccounts] = useState(
    false,
  );
  const [processedAccounts, setProcessedAccounts] = useState(false);

  // ##################### MODAL DATA ################################
  const [
    isEditSavingsAccountModalOpen,
    setIsEditSavingsAccountModalOpen,
  ] = useState(false);
  const [
    editSavingsAccount,
    setEditSavingsAccount,
  ] = useState<SavingsAccountData | null>();
  const [
    isDeleteSavindsAccountModalOpen,
    setIsDeleteSavingsAccountModalOpen,
  ] = useState(false);
  const [
    deleteSavingsAccount,
    setDeleteSavingsAccount,
  ] = useState<SavingsAccountData | null>();
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(
    false,
  );
  const [deleteAccount, setDeleteAccount] = useState<AccountData | null>();
  // ##################### MODAL DATA ################################

  const containerRef = useRef<HTMLDivElement>(null);
  const accountModalFormRef = useRef<FormHandles>(null);

  // Fetch accounts data
  useEffect(() => {
    api.get<AccountData[]>('account').then((response) => {
      const formattedAccounts = response.data
        .filter((acc) => acc.active)
        .map((acc) => {
          let formattedWeight;
          if (acc.weight > 0) {
            const weightValue = acc.weight / 100;
            const roundedWeight = weightValue.toFixed(2);
            formattedWeight = `${roundedWeight} %`;
          } else {
            formattedWeight = '--';
          }

          return {
            ...acc,
            formattedWeight,
          };
        });
      setAccounts(formattedAccounts);
      setProcessedAccounts(true);
    });
  }, []);

  // Fetch savings accounts data
  useEffect(() => {
    api.get<SavingsAccountData[]>('savings-account').then((response) => {
      const savingsAccs = response.data
        .filter((savingsAccount) => savingsAccount.active)
        .map((savingsAccount) => {
          const formatted_goal = formatValue(savingsAccount.goal / 100);
          return {
            ...savingsAccount,
            formatted_goal,
          };
        });

      savingsAccs.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      setSavingsAccounts(savingsAccs);
      setProcessedSavingsAccounts(true);
    });
  }, []);

  useEffect(() => {
    if (processedAccounts && processedSavingsAccounts) {
      setLoading(false);
    }
  }, [processedAccounts, processedSavingsAccounts]);

  const handleUpdateSavingsAccount = useCallback(
    async (data: EditSavingsAccountData) => {
      const editSavingsAccPayload = {
        id: editSavingsAccount?.id,
        name: data.name,
        description: data.description,
        goal: data.goal * 100,
      };

      const updatedSavingsAccount = await api.put<SavingsAccountData>(
        'savings-account',
        editSavingsAccPayload,
      );

      const newSavingsAccountIndex = savingsAccounts.findIndex(
        (savingsAccount) => {
          return savingsAccount.id === updatedSavingsAccount?.data.id;
        },
      );

      const currentSavingsAccounts = savingsAccounts;

      const updatedSavingsAcc = {
        id: editSavingsAccount ? editSavingsAccount.id : 'id',
        name: data.name,
        description: data.description,
        goal: data.goal,
        type: 'SAVINGS',
        formatted_goal: formatValue(data.goal),
      };

      currentSavingsAccounts.splice(
        newSavingsAccountIndex,
        1,
        updatedSavingsAcc,
      );

      currentSavingsAccounts.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      setSavingsAccounts(currentSavingsAccounts);
      setIsEditSavingsAccountModalOpen(false);
      setEditSavingsAccount(null);
    },
    [editSavingsAccount, savingsAccounts],
  );

  const handleDeleteSavingsAccount = useCallback(
    async (savingsAccountID: string) => {
      await api.delete(`savings-account/${savingsAccountID}`);

      const currentSavingsAccounts = savingsAccounts;

      const deletedSavingsAccountIndex = currentSavingsAccounts.findIndex(
        (savingsAccount) => {
          return savingsAccount.id === savingsAccountID;
        },
      );
      currentSavingsAccounts.splice(deletedSavingsAccountIndex, 1);

      setSavingsAccounts(currentSavingsAccounts);
      setIsDeleteSavingsAccountModalOpen(false);
      setProcessedSavingsAccounts(true);
      setDeleteSavingsAccount(null);
    },
    [savingsAccounts],
  );

  const handleDeleteAccount = useCallback(async (accountID: string) => {
    await api.delete(`account/${accountID}`);

    api.get<AccountData[]>('account').then((response) => {
      const formattedAccounts = response.data.map((acc) => {
        let formattedWeight;
        if (acc.weight > 0) {
          const weightValue = acc.weight / 100;
          const roundedWeight = weightValue.toFixed(2);
          formattedWeight = `${roundedWeight} %`;
        } else {
          formattedWeight = '--';
        }

        return {
          ...acc,
          formattedWeight,
        };
      });
      setAccounts(formattedAccounts);
      setProcessedAccounts(true);
      setIsDeleteAccountModalOpen(false);
      setDeleteAccount(null);
    });
  }, []);

  const handleOpenModal = useCallback(
    ({ modalName, savingsAccountData, accountData }: ModalData) => {
      switch (modalName) {
        case 'deleteAccount':
          setIsDeleteAccountModalOpen(true);
          setDeleteAccount(accountData);
          break;
        case 'editSavingsAccount':
          setIsEditSavingsAccountModalOpen(true);
          setEditSavingsAccount(savingsAccountData);
          break;
        case 'deleteSavingsAccount':
          setIsDeleteSavingsAccountModalOpen(true);
          setDeleteSavingsAccount(savingsAccountData);
          break;
        default:
          console.log('Especificar tipo de modal');
      }
    },
    [],
  );

  const handleCloseModal = useCallback((modalName) => {
    switch (modalName) {
      case 'deleteAccount':
        setIsDeleteAccountModalOpen(false);
        setDeleteAccount(null);
        break;
      case 'editSavingsAccount':
        setIsEditSavingsAccountModalOpen(false);
        setEditSavingsAccount(null);
        break;
      case 'deleteSavingsAccount':
        setIsDeleteSavingsAccountModalOpen(false);
        setDeleteSavingsAccount(null);
        break;
      default:
        console.log('Especificar tipo de modal');
    }
  }, []);

  return loading ? (
    <Container>
      <Toolbar />
      <LoadingAnimation />
    </Container>
  ) : (
    <Container ref={containerRef}>
      <Toolbar />
      {isEditSavingsAccountModalOpen && (
        <Modal
          open={isEditSavingsAccountModalOpen}
          showCloseIcon={false}
          onClose={() => handleCloseModal('editSavingsAccount')}
          center
          classNames={{
            modal: 'editSavingsAccountModal',
          }}
          container={containerRef.current as Element}
        >
          <UnformForm
            ref={accountModalFormRef}
            onSubmit={handleUpdateSavingsAccount}
          >
            <h2>Editar conta</h2>
            <Input
              name="name"
              icon={FiList}
              placeholder="Nome"
              defaultValue={editSavingsAccount?.name}
            />
            <Input
              name="description"
              icon={FiList}
              placeholder="Descrição"
              defaultValue={editSavingsAccount?.description}
            />
            <Input
              name="goal"
              icon={FiList}
              placeholder="Meta"
              defaultValue={
                editSavingsAccount ? editSavingsAccount.goal / 100 : 0
              }
            />
            <div className="submitSavingsAccount">
              <Button
                type="button"
                onClick={() => handleCloseModal('editSavingsAccount')}
              >
                Cancelar
              </Button>
              <Button type="submit">Salvar alterações</Button>
            </div>
          </UnformForm>
        </Modal>
      )}
      {isDeleteSavindsAccountModalOpen && (
        <Modal
          open={isDeleteSavindsAccountModalOpen}
          showCloseIcon={false}
          onClose={() => handleCloseModal('deleteSavingsAccount')}
          center
          classNames={{
            modal: 'deleteModal',
          }}
          container={containerRef.current as Element}
        >
          <h3>Tem certeza que deseja deletar a seguinte poupança?</h3>
          <DeleteFormData>
            <div className="deleteRow">
              <div className="deleteLabel">Nome:</div>
              <div className="deleteData">{deleteSavingsAccount?.name}</div>
            </div>
            <div className="deleteRow">
              <div className="deleteLabel">Descrição:</div>
              <div className="deleteData">
                {deleteSavingsAccount?.description}
              </div>
            </div>
            <div className="deleteRow">
              <div className="deleteLabel">Meta:</div>
              <div className="deleteData">
                {deleteSavingsAccount?.formatted_goal}
              </div>
            </div>
          </DeleteFormData>
          <div className="submitDeleteTransaction">
            <Button
              type="button"
              onClick={() => handleCloseModal('deleteSavingsAccount')}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              onClick={() => {
                if (deleteSavingsAccount?.id) {
                  handleDeleteSavingsAccount(deleteSavingsAccount?.id);
                }
              }}
            >
              Excluir poupança
            </Button>
          </div>
        </Modal>
      )}
      {isDeleteAccountModalOpen && (
        <Modal
          open={isDeleteAccountModalOpen}
          showCloseIcon={false}
          onClose={() => handleCloseModal('deleteAccount')}
          center
          classNames={{
            modal: 'deleteModal',
          }}
          container={containerRef.current as Element}
        >
          <h3>Tem certeza que deseja deletar a seguinte conta?</h3>
          <DeleteFormData>
            <div className="deleteRow">
              <div className="deleteLabel">Tipo:</div>
              <div className="deleteData">
                {deleteAccount?.type === 'IN' ? 'Entrada' : 'Saída'}
              </div>
            </div>
            <div className="deleteRow">
              <div className="deleteLabel">Nome:</div>
              <div className="deleteData">{deleteAccount?.name}</div>
            </div>
          </DeleteFormData>
          <div className="submitDeleteTransaction">
            <Button
              type="button"
              onClick={() => handleCloseModal('deleteAccount')}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              onClick={() => {
                if (deleteAccount?.id) {
                  handleDeleteAccount(deleteAccount?.id);
                }
              }}
            >
              Excluir conta
            </Button>
          </div>
        </Modal>
      )}
      <AccountsArea>
        <h2>Controle de Contas Cadastradas</h2>
        {accounts.length > 0 || savingsAccounts.length > 0 ? (
          <>
            {/* accounts table */}
            <Subtitle>Contas</Subtitle>
            <AccountsTable>
              <tr>
                <th>Tipo</th>
                <th>Nome</th>
                <th>Peso</th>
              </tr>
              {accounts.map((account) => {
                return (
                  <tr key={account.id}>
                    <td>{account.type === 'IN' ? 'Entrada' : 'Saída'}</td>
                    <td>{account.name}</td>
                    <td>{account.formattedWeight}</td>
                    <td className="lastChildEdit">
                      <button
                        type="submit"
                        onClick={
                          () =>
                            handleOpenModal({
                              modalName: 'deleteAccount',
                              accountData: account,
                            })
                          // eslint-disable-next-line react/jsx-curly-newline
                        }
                      >
                        <FiTrash2 color="#c80800" title="Excluir transação" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </AccountsTable>
            {/* savings accounts table */}
            {savingsAccounts.length > 0 ? (
              <>
                <Subtitle>Poupanças</Subtitle>
                <AccountsTable>
                  <tr>
                    <th>Conta</th>
                    <th>Descrição</th>
                    <th>Valor Poupado</th>
                  </tr>
                  {savingsAccounts.map((savingsAccount) => {
                    return (
                      <tr key={savingsAccount.id}>
                        <td>{savingsAccount.name}</td>
                        <td>{savingsAccount.description}</td>
                        <td>{savingsAccount.formatted_goal}</td>
                        <td className="lastChildEdit">
                          <button
                            type="submit"
                            onClick={
                              () =>
                                handleOpenModal({
                                  modalName: 'editSavingsAccount',
                                  savingsAccountData: savingsAccount,
                                })
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          >
                            <FiEdit title="Editar transação" />
                          </button>
                          <button
                            type="submit"
                            onClick={
                              () =>
                                handleOpenModal({
                                  modalName: 'deleteSavingsAccount',
                                  savingsAccountData: savingsAccount,
                                })
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                          >
                            <FiTrash2
                              color="#c80800"
                              title="Excluir poupança"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </AccountsTable>
              </>
            ) : (
              <>
                <Subtitle>Poupanças</Subtitle>
                <div>Você ainda não tem poupanças cadastradas.</div>
                <br />
                <div>
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  <Link to="savings-account-register">Clique aqui</Link> para
                  criar e comece a poupar agora mesmo!
                </div>
                <br />
              </>
            )}
          </>
        ) : (
          <>
            <div>
              Parece que ainda não há contas ou poupanças cadastradas em sua
              aplicação.
            </div>
            <br />
            <div>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <Link to="account-register">Clique aqui</Link> para fazer fazer
              seu primeiro cadastro agora mesmo!
            </div>
          </>
        )}
      </AccountsArea>
    </Container>
  );
};

export default Accounts;
