import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { FiCalendar } from 'react-icons/fi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Container } from './styles';

interface MonthPickerProps {
  name: string;
  defaultDate?: Date;
}

const DayPicker: React.FC<MonthPickerProps> = ({
  name,
  defaultDate = new Date(),
  ...rest
}) => {
  const daypickerRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  const [startDate, setStartDate] = useState(
    defaultDate ? new Date(defaultDate) : defaultDate,
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleSetDate = useCallback(
    (date) => {
      setStartDate(date);
      setIsOpen(!isOpen);
    },
    [isOpen],
  );

  const openDatepicker = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: daypickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      Data:
      <DatePicker
        ref={daypickerRef}
        selected={startDate}
        open={isOpen}
        onChange={handleSetDate}
        onFocus={openDatepicker}
        dateFormat="dd/MM/yyyy"
        readOnly
        {...rest}
      />
      <FiCalendar size={20} onClick={openDatepicker} />
    </Container>
  );
};

export default DayPicker;
