import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import signInBackgroundImg from '../../assets/signin.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const ContentArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 800px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const ContentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 470px;
  height: 640px;

  background: #17171e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  animation: ${appearFromLeft} 1s;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;
    margin: 100px 50px;
    align-items: center;
    justify-content: center;
    width: 330px;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #b8b8b8;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#b8b8b8')};
      }
    }
  }

  > a {
    color: #eea302;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#eea302')};
    }

    svg {
      margin-right: 8px;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: 100% 120%;
`;
