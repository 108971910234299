import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingCircle = styled.div`
  width: 4rem;
  height: 4rem;

  border: 8px solid #a2a2a5;
  border-top: 8px solid #eea302;
  border-radius: 50%;

  animation: ${rotateAnimation} 1s linear infinite;
`;
