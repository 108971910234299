import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;

  animation: ${fadeIn} 0.3s;

  background: #17171e;

  position: absolute;
  top: 30px;

  display: none;
  border: 1px solid white;

  div {
    display: none;
    flex-direction: column;
    margin-top: 35px;
    animation: ${fadeIn} 0.3s;

    a {
      display: flex;
      padding: 0 0 20px 10px;

      width: 200px;

      font-size: 18px;
      color: #b8b8b8;
      margin-top: 20px;
      text-decoration: none;

      color: #eea302;
    }

    &:hover div {
      display: block;
      background: #17171e;
    }
  }
`;
