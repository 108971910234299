import React from 'react';
import { MenuContainer } from './styles';

const TransactionDropdown: React.FC = ({ children }) => (
  <MenuContainer>
    <div>{children}</div>
  </MenuContainer>
);

export default TransactionDropdown;
