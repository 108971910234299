import React from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { Container } from './styles';

interface MenuButtonProps {
  title: string;
  hasIcon?: boolean;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  title,
  children,
  hasIcon = true,
}) => {
  return (
    <Container className="desktop">
      <div>
        <span>{title}</span>
        {hasIcon && <FiChevronDown size={24} />}
      </div>
      {children}
    </Container>
  );
};

export default MenuButton;
