import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
`;

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const FormArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  animation: ${appearFromBottom} 1s;

  width: 630px;
  height: 547px;
  margin-top: 140px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  background: #17171e;
  border-radius: 10px;

  form {
    width: 370px;

    h2 {
      font-size: 32px;
      color: #7086f5;
      padding-bottom: 48px;
    }

    input {
      width: 280px;
    }
  }
`;
