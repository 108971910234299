import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
`;

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const FormArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  animation: ${appearFromBottom} 1s;

  width: 630px;
  height: 700px;
  margin-top: 140px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  background: #17171e;
  border-radius: 10px;

  form {
    width: 370px;

    h2 {
      font-size: 32px;
      color: #7086f5;
      padding-bottom: 48px;
    }

    input {
      width: 280px;
    }
  }
`;

export const MessageArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;

  width: 630px;
  height: 300px;
  padding: 0 30px;
  margin-top: 140px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  background: #17171e;
  border-radius: 10px;

  animation: ${appearFromBottom} 1s;

  a {
    color: #eea302;
  }
`;

export const InstallmentsAmountContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;

  div:first-of-type {
    width: 65%;

    input {
      width: 100%;
    }

    div {
      max-width: 20%;
    }
  }

  div:nth-of-type(2) {
    width: 35%;

    input {
      width: 100%;
    }

    div {
      max-width: 20%;
    }
  }
`;
