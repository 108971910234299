import React, { useCallback, useRef } from 'react';
import { FiMail, FiLock, FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import logoImg from '../../assets/logo.svg';

import Button from '../../components/Button';
import Input from '../../components/Input';
import getValidationErrors from '../../utils/getValidationErros';

import { useToast } from '../../hooks/toast';

import api from '../../services/api';

import { Container, ContentArea, ContentBox, Content } from './styles';

interface ISignUpProps {
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ISignUpProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(8, 'Mínimo de 8 dígitos'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            'Confirmação incorreta',
          ),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/register', {
          email: data.email,
          password: data.password,
        });

        history.push('/');

        addToast({
          type: 'success',
          title: 'Cadastro realizado!',
          description: 'Você já pode fazer seu logon!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (
          err.response.data.message &&
          err.response.data.message === 'Email already in use'
        ) {
          addToast({
            type: 'error',
            title: 'E-mail em uso',
            description:
              'O e-mail escolhido já está sendo utilizado, escolha outro',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao fazer cadastro, tente novamente',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <ContentArea>
        <ContentBox>
          <Content>
            <img src={logoImg} alt="MyFinances" />

            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Crie sua conta</h1>
              <Input name="email" icon={FiMail} placeholder="E-mail" />
              <Input
                name="password"
                icon={FiLock}
                placeholder="Senha"
                type="password"
              />
              <Input
                name="password_confirmation"
                icon={FiLock}
                placeholder="Confirmação de senha"
                type="password"
              />
              <Button>Criar Conta</Button>
            </Form>

            <Link to="/">
              <FiArrowLeft />
              Voltar para logon
            </Link>
          </Content>
        </ContentBox>
      </ContentArea>
    </Container>
  );
};

export default SignUp;
