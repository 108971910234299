import styled from 'styled-components';

export const Container = styled.label`
  display: flex;
  align-items: center;

  margin-bottom: 16px;

  div {
    margin-right: 10px;
  }
`;
